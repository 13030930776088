import { createBrowserRouter } from "react-router-dom";
import AppContainer from "@components/App/AppContainer";
import ErrorPage from "@pages/ErrorPage/ErrorPage";
import SignInPage from "@pages/AuthPage/AuthPage";
import ContractorsPage from "@pages/ContractorsPage/ContractorsPage";
import Notifications from "@pages/Notifications/Notifications";
import OrderList from "@pages/OrdersPage/OrderList/OrderList";
import CreateOrder from "@pages/OrdersPage/create/CreateOrder";
import ProfilePage from "@pages/ProfilePage/ProfilePage";
import ReportsPage from "@pages/ReportsPage/ReportsPage";
import TasksHistoryPage from "@pages/TasksHistoryPage/TasksHistoryPage";
import TasksPage from "@pages/TasksPage/TasksPage";
import ProtectedRoute from "./ProtectedRoute";
import OrderItem from "@pages/OrdersPage/OrderItem/OrderItem";
import CompleteRegistration from "@pages/CompleteRegistration/CompleteRegistration";
import { USER_ROLE } from "@const/roles";
import ProfilePageEdit from "@pages/ProfilePage/edit/ProfilePageEdit";
import ErrorLayout from "@components/App/ErrorLayout";
import PasswordRecovery from "@pages/PasswordRecovery/PasswordRecovery";

const RouteConfig = (route) => {
	return {
		path: route.path,
		element: <ProtectedRoute page={route.element} roles={route.roles} />,
	};
};

export const routes = [
	{ path: "signin", element: <SignInPage /> },
	{ path: "complete_registration", element: <CompleteRegistration /> },
	{ path: "password_recovery", element: <PasswordRecovery /> },
	{
		path: "contractors",
		element: <ContractorsPage />,
		roles: [USER_ROLE.MANAGER, USER_ROLE.OPERATIONAL_DIRECTOR, USER_ROLE.ADMINISTRATOR],
	},
	{
		path: "reports",
		element: <ReportsPage />,
		roles: [USER_ROLE.MANAGER, USER_ROLE.OPERATIONAL_DIRECTOR],
	},
	{
		path: "orders",
		element: <OrderList />,
		roles: [USER_ROLE.MANAGER, USER_ROLE.OPERATIONAL_DIRECTOR, USER_ROLE.ADMINISTRATOR],
	},
	{
		path: "orders/create",
		element: <CreateOrder />,
		roles: [USER_ROLE.MANAGER, USER_ROLE.OPERATIONAL_DIRECTOR, USER_ROLE.ADMINISTRATOR],
	},
	{
		path: "orders/:id",
		element: <OrderItem />,
		roles: [USER_ROLE.MANAGER, USER_ROLE.OPERATIONAL_DIRECTOR, USER_ROLE.ADMINISTRATOR],
	},
	{
		path: "orders/edit/:id",
		element: <CreateOrder />,
		roles: [USER_ROLE.MANAGER, USER_ROLE.OPERATIONAL_DIRECTOR, USER_ROLE.ADMINISTRATOR],
	},
	{
		path: "profile",
		element: <ProfilePage />,
		roles: [
			USER_ROLE.MANAGER,
			USER_ROLE.CONTRACTOR,
			USER_ROLE.OPERATIONAL_DIRECTOR,
			USER_ROLE.ADMINISTRATOR,
		],
	},
	{
		path: "profile/:id",
		element: <ProfilePage />,
		roles: [
			USER_ROLE.MANAGER,
			USER_ROLE.CONTRACTOR,
			USER_ROLE.OPERATIONAL_DIRECTOR,
			USER_ROLE.ADMINISTRATOR,
		],
	},
	{
		path: "profile/edit",
		element: <ProfilePageEdit />,
		roles: [
			USER_ROLE.MANAGER,
			USER_ROLE.CONTRACTOR,
			USER_ROLE.OPERATIONAL_DIRECTOR,
			USER_ROLE.ADMINISTRATOR,
		],
	},
	{ path: "tasks", element: <TasksPage />, roles: [USER_ROLE.CONTRACTOR] },
	{ path: "tasks_history", element: <TasksHistoryPage />, roles: [USER_ROLE.CONTRACTOR] },
	{
		path: "notifications",
		element: <Notifications />,
		roles: [USER_ROLE.CONTRACTOR, USER_ROLE.OPERATIONAL_DIRECTOR, USER_ROLE.MANAGER],
	},
];

export const router = createBrowserRouter([
	{
		path: "/",
		element: <AppContainer />,
		children: routes.map((route) => RouteConfig(route)),
		errorElement: (
			<ErrorLayout>
				<ErrorPage errorCode={404} message="Не нашли такую страницу" />
			</ErrorLayout>
		),
	},
]);
