import { create } from "zustand";
import useRequestStore from "./RequestStore";

const useDictionaryStore = create((set, get) => ({
	messagesType: [],
	greetingMessageList: [],
	complimentMessageList: [],
	supportMessageList: [],
	getMessagesType: () => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("supporting_message_types:list", {
			requestData: { page: 1, limit: 100 },
			successCb: (responseData) => {
				console.log("Список типов сообщений получен:", responseData);
				set({ messagesType: responseData.items });
			},
			errorCb: (error) => {
				console.error("Ошибка получение типов сообщений:", error);
			},
		});
	},
	getRandomMessage: ({ type_code, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("supporting_messages:view_random", {
			requestData: { type_code },
			successCb: (responseData) => {
				console.log("Случайное сообщение получено:", responseData);
				successCb && successCb(responseData.item);
			},
			errorCb: (error) => {
				console.error("Ошибка получение случайного сообщения:", error);
			},
		});
	},
	getTypeCode: (type) => {
		return get().messagesType.find((item) => item.code === type)?.code;
	},
}));

export default useDictionaryStore;
