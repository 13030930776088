import React from "react";
import PageTitle from "@components/ui/PageTitle/PageTitle";
import { USER_STATUS_COLOR } from "@const/colors";
import classes from "./ProfileTopInfo.module.css";
import useProfileStore from "@store/ProfileStore";
const ProfileTopInfo = () => {
	const { profileInfo } = useProfileStore();
	return (
		<div className={classes.headerWrapper}>
			<div className={classes.avatarWrapper}>
				<img
					src={profileInfo?.photo || "/profile/Avatar2.png"}
					alt="avatar_default"
					width={64}
					height={64}
					className={classes.avatar}
				/>
				<div>
					<p
						className={`text-sm-medium ${classes.status}`}
						style={{ color: USER_STATUS_COLOR[profileInfo?.state?.code] }}
					>
						{profileInfo?.state?.title}
					</p>
					<PageTitle
						title={`${profileInfo?.name} ${profileInfo?.surname}`}
						className={`mobile-hide ${classes.title}`}
					/>
					<div className={`text-sm-medium ${classes.roleList} `}>
						{profileInfo?.roles.map((role, index) => {
							return (
								<span key={role.role.id} className={classes.role}>
									{role.role.title}
									{index !== profileInfo.roles.length - 1 && <span>,&nbsp;</span>}
								</span>
							);
						})}
					</div>
				</div>
			</div>
			<PageTitle
				title={
					<>
						<span>{profileInfo?.name}</span>
						<span>{profileInfo?.surname}</span>
					</>
				}
				className={`${classes.titleMobile} desktop-hide`}
			/>
		</div>
	);
};

export default ProfileTopInfo;
