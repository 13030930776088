import { create } from "zustand";
import { convertToISO8601Duration } from "@helpers/day";
import useRequestStore from "./RequestStore";
import useModuleStore from "./ModuleStore";
import { SOCIALS_CODE } from "@const/socials";
import { TASK_TYPE } from "@const/tasks";

const useOrderStore = create((set, get) => ({
	orderList: null,
	currentOrder: null,
	customerList: null,
	totalOrders: null,
	socialNetworkList: null,
	socialNetworkActivitiesList: null,
	selectedSocial: null,
	orderData: {
		completionTime: "",
		customer: "",
		url: "",
		isDifferedOrder: false,
		started_on_date: "",
		started_on_time: "",
		comment: "",
		activityComments: "",
	},
	isFieldValid: {
		customer: true,
		completionTime: true,
		url: true,
		activeTasks: true,
		started_on_date: true,
		started_on_time: true,
		comment: true,
		activityComments: true,
	},
	activeTasks: [],
	orderTaskList: [],
	orderTaskCount: [],

	updateOrderData: (key, value) => {
		set((state) => ({
			orderData: {
				...state.orderData,
				[key]: value,
			},
		}));
	},
	clearCustomerList: () => {
		set({
			customerList: null,
		});
	},
	setSelectedSocial: (social) => {
		set({
			selectedSocial: social,
		});
	},
	setActiveTasks: (newTasks) => {
		set({
			activeTasks: newTasks,
		});
	},
	setIsFieldValid: (result) => {
		set({
			isFieldValid: result,
		});
	},
	clearCurrentOrder: () => {
		set({
			currentOrder: null,
		});
	},
	getActivityComments: () => {
		return get()
			.currentOrder?.order_activities.filter(
				({ social_network_activity }) =>
					social_network_activity?.activity?.code === TASK_TYPE.COMMENT
			)
			.flatMap(({ items }) => items.map(({ title }) => title));
	},
	formatActivity: (activeTasks) => {
		const { getTaskId, orderData } = get();

		return activeTasks
			.filter(({ quantity }) => quantity && quantity !== "0")
			.map((task) => {
				const { activity, social_network_activity, quantity } = task;
				const taskCode = activity?.code || social_network_activity?.activity?.code;

				const baseTask = {
					social_network_activity_id: getTaskId(task),
					quantity,
				};

				if (taskCode === TASK_TYPE.COMMENT) {
					return {
						...baseTask,
						items: orderData.activityComments,
					};
				}

				return baseTask;
			});
	},
	validateFields: () => {
		const orderData = get().orderData;

		const isValidInstagramURL = (url) => {
			return url.includes(SOCIALS_CODE.INSTAGRAM);
		};

		const validationResults = {
			customer: !!orderData.customer,
			completionTime: orderData.completionTime
				? !!convertToISO8601Duration(orderData.completionTime)
				: false,
			url: orderData.isDifferedOrder ? true : isValidInstagramURL(orderData.url),
			comment: !!orderData.comment,
			activeTasks: get().activeTasks?.length > 0,
		};
		if (orderData.isDifferedOrder) {
			validationResults.started_on_date = !!orderData.started_on_date;
			validationResults.started_on_time = !!orderData.started_on_time;
		}

		get().setIsFieldValid(validationResults);

		for (let key in validationResults) {
			if (!validationResults[key]) {
				console.error(`${key} is invalid`);
				return;
			}
		}
		return true;
	},
	getOrderList: ({ page, limit, sort }) => {
		const sortFieldMapping = {
			state: "state_id",
			customer: "customer_id",
			number: "number",
			finished_on: "finished_on",
		};

		const formSortField = (sort) => {
			if (!sort.length) return "-update_on";

			const { id, desc } = sort[0];
			const sortField = sortFieldMapping[id] || "";
			const direction = desc ? "-" : "";

			return `${direction}${sortField}`;
		};

		const handleRequest = useRequestStore.getState().handleRequest;

		handleRequest("orders:list", {
			requestData: { page, limit, sort: formSortField(sort) },
			successCb: (responseData) => {
				set({
					orderList: responseData.items,
					totalOrders: responseData.total,
				});
			},
			errorCb: (error) => {
				console.error("Ошибка получения списка заказов:", error);
			},
		});
	},
	getOrderInfo: (id) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("orders:view", {
			requestData: { entity_id: id },
			successCb: (responseData) => {
				set({
					currentOrder: responseData.item,
				});
			},
			errorCb: (error) => {
				console.error("Ошибка получения заказа:", error);
			},
		});
	},
	getCustomerList: ({ page, limit, filters }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("customers:list", {
			requestData: { page, limit, filters },
			successCb: (responseData) => {
				set({
					customerList: responseData.items,
				});
			},
			errorCb: (error) => {
				console.error("Ошибка получения списка заказчиков:", error);
			},
		});
	},
	calculateSum: (activeTasks) => {
		return activeTasks?.reduce((total, task) => {
			if (task.social_network_activity) {
				return total + task.social_network_activity.price.customer_price * task.quantity;
			}
			return total + task.price.customer_price * task.quantity;
		}, 0);
	},
	createOrder: (data, navigate, isEdit, id) => {
		const { handleRequest } = useRequestStore.getState();
		const actionType = isEdit ? "orders:update" : "orders:create";
		const requestData = isEdit ? { entity_id: id, entity_data: data } : data;
		console.log("reqDat", requestData);
		handleRequest(actionType, {
			requestData,
			successCb: (responseData) => {
				console.log(`Заказ ${isEdit ? "обновлен" : "создан"}: ${JSON.stringify(responseData)}`);
				get().resetOrderForm();
				navigate(`/orders/${responseData.item.id}`);
			},
			errorCb: (error) => {
				const action = isEdit ? "редактирования" : "создания";
				console.error(`Ошибка ${action} заказа:`, error.title);
			},
		});
	},
	changeOrderStatus: ({ id, status, comment }) => {
		const getOrderModule = useModuleStore.getState().getOrderModule;
		const orderModule = getOrderModule();
		if (!orderModule) return;

		const statusId = orderModule?.states.find((el) => el.code === status).id;
		const handleRequest = useRequestStore.getState().handleRequest;

		handleRequest("orders:change_state", {
			requestData: { entity_id: id, state_id: statusId, cancellation_comment: comment },
			successCb: (responseData) => {
				console.log(`Статус заказа изменен ${responseData}`);
				get().getOrderInfo(id);
			},
			errorCb: (error) => {
				console.error(error.title);
			},
		});
	},

	getSocialNetworkList: ({ page, limit, filters, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("social_networks:list", {
			requestData: { page, limit, filters },
			successCb: (responseData) => {
				set({
					socialNetworkList: responseData.items,
				});
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка получения списка социальных сетей:", error);
			},
		});
	},
	getSocialNetworkActivitiesList: ({ page, limit, filters }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("social_network_activities:list", {
			requestData: { page, limit, filters },
			successCb: (responseData) => {
				set({
					socialNetworkActivitiesList: responseData.items,
				});
			},
			errorCb: (error) => {
				console.error("Ошибка получения списка активностей социальной сети:", error);
			},
		});
	},

	getInstagramInfo: () => {
		const socialNetworkList = get().socialNetworkList;
		return socialNetworkList?.length
			? socialNetworkList?.filter((list) => list.code === SOCIALS_CODE.INSTAGRAM)[0]
			: null;
	},
	getTaskId: (task) => task.social_network_activity?.id || task.id,
	getTaskActivityId: (task) => task.social_network_activity?.activity_id || task.activity_id,
	getTaskTitle: (task) => task.social_network_activity?.activity?.title || task.activity?.title,
	resetOrderForm: () => {
		set({
			orderData: {
				completionTime: "",
				customer: "",
				url: "",
				comment: "",
				isDifferedOrder: false,
				comments: "",
			},
			isFieldValid: {
				customer: true,
				completionTime: true,
				url: true,
				comment: true,
				activeTasks: true,
			},
			activeTasks: [],
		});
	},
	createOrderHandler: (e, navigate, isEdit, id) => {
		e.preventDefault();
		const formValid = get().validateFields();
		if (!formValid) return;

		const orderData = get().orderData;

		const data = {
			social_network_id: get().selectedSocial.id,
			completion_time: orderData.completionTime
				? convertToISO8601Duration(orderData.completionTime)
				: null,
			customer_id: orderData.customer.id,
			url: orderData.url,
			comment: orderData.comment,
			social_network_activities: get().formatActivity(get().activeTasks),
		};
		if (orderData.isDifferedOrder) {
			const { started_on_date, started_on_time } = orderData;

			if (!started_on_date || !started_on_time) return;

			const timeWithSeconds = `${started_on_time}:00`;
			const dateTimeString = `${started_on_date}T${timeWithSeconds}`;

			data.started_on = new Date(dateTimeString)?.toISOString();
		}
		get().createOrder(data, navigate, isEdit, id);
	},

	getOrderTaskCount: (id) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("order_user_activities:states_by_order", {
			requestData: { page: "1", limit: "10", entity_id: id },
			successCb: (responseData) => {
				set({
					orderTaskCount: responseData.items,
				});
			},
			errorCb: (error) => {
				console.error("Ошибка получения количества заданий в заказе:", error);
			},
		});
	},

	getOrderTaskList: ({ page, limit, filters, id, successCb, finallyCb }) => {
		const useFilters = filters.state_id[0]
			? {
					state_id: filters.state_id,
			  }
			: null;
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("order_user_activities:list_by_order", {
			requestData: {
				page,
				limit,
				entity_id: id,
				filters: useFilters,
			},
			successCb: (responseData) => {
				successCb && successCb(responseData);
				set({
					orderTaskList: responseData.items,
				});
			},
			errorCb: (error) => {
				console.error("Ошибка получения заданий в заказе:", error);
			},
			finallyCb: () => {
				finallyCb && finallyCb();
			},
		});
	},
	addUrlOrder: ({ id, url, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("orders:patch", {
			requestData: {
				entity_id: id,
				entity_data: {
					url,
				},
			},
			successCb: (responseData) => {
				successCb && successCb();
			},
			errorCb: (error) => {
				console.error("Ошибка добавления url в заказ:", error);
			},
		});
	},
	getCommentActivityId: () => {
		let commentActivity = get().socialNetworkActivitiesList.filter(
			(socAcitivty) => socAcitivty.activity.code === TASK_TYPE.COMMENT
		);
		return commentActivity[0] ? commentActivity[0].id : null;
	},
	parseComments: ({ items, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("orders:parse_activity_items", {
			requestData: {
				social_network_activity_id: get().getCommentActivityId(),
				items,
			},
			successCb: (responseData) => {
				successCb && successCb(responseData.items);
			},
			errorCb: (error) => {
				console.error("Ошибка парсинга комментариев:", error);
			},
		});
	},
}));

export default useOrderStore;
