import { ReactComponent as Comment } from "@assets/icons/task/comment.svg";
import { ReactComponent as Like } from "@assets/icons/task/like.svg";
import { ReactComponent as Live } from "@assets/icons/task/live.svg";
import { ReactComponent as Repost } from "@assets/icons/task/repost.svg";
import { ReactComponent as Saving } from "@assets/icons/task/saving.svg";
import { ReactComponent as Stories } from "@assets/icons/task/stories.svg";
import { ReactComponent as ViewStory } from "@assets/icons/task/view_story.svg";
import BrandButton from "@components/ui/Button/BrandButton";
import { ORDER_STATUS } from "@const/order";
import { calculateTimeDifference } from "@helpers/day";
import useModalStore from "@store/ModalStore";
import useTaskStore from "@store/TaskStore";
import React from "react";
import { formatDate } from "../../../helpers/day";
import TaskFullScreen from "../TaskFullScreen/TaskFullScreen";
import classes from "./TaskCard.module.css";

const TASK_TYPE = {
	like: <Like />,
	repost: <Repost />,
	comment: <Comment />,
	answer_story_question: <Stories />,
	view_live: <Live />,
	view_story: <ViewStory />,
	saving: <Saving />,
};

const TaskCard = ({ task, loadTasks, clearTasks }) => {
	const { showModal } = useModalStore();
	const { takeTask, getMyTasks } = useTaskStore();
	const socialActivity =
		task.social_network_activity || task.order_activity?.social_network_activity;
	const activityCode = socialActivity?.activity.code;
	const activityLabel = socialActivity?.label;
	const stateCode = task.state?.code || task.order?.state.code;

	const getStatusText = (stateCode, task) => {
		const statusTexts = {
			[ORDER_STATUS.UNVERIFIED]: "На проверке",
			[ORDER_STATUS.CANCELLED]: "Отменено",
			[ORDER_STATUS.REJECTED]: "Отклонено",
			[ORDER_STATUS.COMPLETED]: "Выполнено",
			[ORDER_STATUS.EXPIRED]: task.user_social_network_account
				? "Таймер вышел, задание не выполнено"
				: "Срочное",
			[ORDER_STATUS.APPROVED]: task.finished_on
				? calculateTimeDifference(task.finished_on)
					? `В работе еще ${calculateTimeDifference(task.finished_on)}`
					: "Время вышло"
				: "Доступно",
			[ORDER_STATUS.PENDING]:
				task?.state?.title === "Забронировано"
					? "Забронированное"
					: `Отложено на ${formatDate({
							dateString: task.order.started_on,
							withTime: true,
							month: true,
					  })}`,
		};

		return statusTexts[stateCode] || "Доступно";
	};

	const statusText = getStatusText(stateCode, task);

	const openTaskModal = (taskData) => {
		showModal(
			<TaskFullScreen
				task={taskData}
				statusText={statusText}
				takeTaskHandler={takeTaskHandler}
				activityLabel={activityLabel}
				loadTasks={loadTasks}
				clearTasks={clearTasks}
				openTaskModal={openTaskModal}
			/>,
			"cardModal"
		);
	};

	const takeTaskHandler = (id) => {
		takeTask({
			id,
			successCb: (taskNewState) => {
				openTaskModal(taskNewState);
				clearTasks && clearTasks();
				loadTasks && loadTasks({ page: 1 });
				getMyTasks({ page: 1, limit: 100 });
			},
		});
	};

	return (
		<div className={classes.wrapper} onClick={() => openTaskModal(task)}>
			<div className={classes.contentWrapper}>
				<div className={classes.imgWrapper}>{TASK_TYPE[activityCode]}</div>
				<div>
					<p className={`text-md-medium ${classes.title}`}>{activityLabel}</p>
					<p className={`text-xs-medium ${classes.status}`}>{statusText}</p>
				</div>
			</div>

			{!task.order_activity_id && (
				<BrandButton
					className={`${classes.button} btn-brand-non-outline`}
					onClick={(e) => {
						e.stopPropagation();
						takeTaskHandler(task.id);
					}}
				>
					{task.order.state.code === ORDER_STATUS.PENDING ? "Забронировать" : "В работу"}
				</BrandButton>
			)}
		</div>
	);
};

export default TaskCard;
