import React from "react";
import { Form } from "react-bootstrap";
import classes from "./BrandCheckbox.module.css";

const BrandCheckbox = ({ checked, onChange, onClick, label, name, className }) => {
	const handleChange = (e) => {
		onChange(e.target.checked);
	};

	return (
		<Form.Check
			type="checkbox"
			id={name || "custom-checkbox"}
			name={name}
			label={label}
			checked={checked}
			onChange={handleChange}
			onClick={onClick}
			className={`${className || ""}`}
		/>
	);
};

export default BrandCheckbox;
