import { create } from "zustand";
import useRequestStore from "./RequestStore";

const useTransactionStore = create((set, get) => ({
	userTransactionList: [],

	setUserTransactionList: (userTransactionList) => set({ userTransactionList }),
	balanceDebiting: ({ user_ids, successCb, errorCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:balance_debiting_withdrawal", {
			requestData: { user_ids },
			successCb: (responseData) => {
				successCb && successCb(responseData);
				console.log("Успешное списание баланса");
			},
			errorCb: (error) => {
				console.error("Ошибка списания баланса:", error);
			},
		});
	},
	balanceDebitingFine: ({ user_id, amount, description, successCb, errorCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:balance_debiting_fine", {
			requestData: { user_id, amount, description },
			successCb: (responseData) => {
				successCb && successCb(responseData);
				console.log("Списание суммы штрафа успешно");
			},
			errorCb: (error) => {
				console.error("Ошибка списания суммы штрафа:", error);
				errorCb && errorCb(error.title);
			},
		});
	},
	getUserTransactionList: ({ id, page, limit, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		const useFilter = id ? { user_id: id } : null;
		const event = id ? "user_wallet_transactions:list" : "user_wallet_transactions:list_my";
		handleRequest(event, {
			requestData: { page, limit, filters: useFilter },
			successCb: (responseData) => {
				get().setUserTransactionList(responseData.items);
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка получения списка транзакций:", error);
			},
		});
	},
}));

export default useTransactionStore;
