import React from "react";
import TransactionItem from "../TransactionItem/TransactionItem";
import classes from "./TransactionList.module.css";

const TransactionList = ({ data }) => {
	if (!data.length) return <p className="text-sm-medium">Нет переводов и пополнений</p>;

	return (
		<div className={classes.wrapper}>
			{data.map((transaction) => (
				<TransactionItem item={transaction} key={transaction.id} />
			))}
		</div>
	);
};

export default TransactionList;
