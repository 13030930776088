import React, { useState, useEffect } from "react";
import { ReactComponent as PlusCounter } from "@assets/icons/counter/plusCounter.svg";
import { ReactComponent as MinusCounter } from "@assets/icons/counter/minusCounter.svg";
import classes from "./Counter.module.css";
import { onlyNumbersValue } from "@helpers/index";
const Counter = ({ onValueChange, min = 0, max, step = 1, value }) => {
	const [counter, setCounter] = useState(value || "");

	useEffect(() => {
		if (onValueChange) {
			onValueChange(counter);
		}
	}, [counter]);

	const handlePlus = (e) => {
		e.preventDefault();
		setCounter((prev) => +prev + +step);
	};

	const handleMinus = (e) => {
		e.preventDefault();
		if (counter > min) {
			setCounter((prev) => +prev - +step);
		}
	};

	const handleChange = (event) => {
		const inputValue = event.target.value;

		const filteredValue = inputValue.replace(/[^0-9]/g, ""); // Удаляем все нецифровые символы

		// Удаляем начальные нули, оставляя ноль, если это единственное значение
		const processedValue = filteredValue
			.replace(/^0+(?!$)/, "") // Удаляем начальные нули, кроме случая "0"
			.replace(/^$/, "0"); // Устанавливаем значение в "0", если оно пустое

		setCounter(processedValue);
	};

	return (
		<div className={classes.counterWrapper}>
			<input
				type="number"
				value={counter}
				onChange={handleChange}
				className={classes.count}
				min={min}
				onKeyPress={onlyNumbersValue}
			/>
			<button onClick={handlePlus} className={classes.plus}>
				<PlusCounter />
			</button>
			<button onClick={handleMinus} className={classes.minus}>
				<MinusCounter />
			</button>
		</div>
	);
};

export default Counter;
