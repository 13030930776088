import React, { useEffect, useState, useCallback, useRef } from "react";
import { debounce } from "@helpers/index";
import { ReactComponent as ArrowCircle } from "@assets/icons/input/arrowCircleUpRight.svg";
import classes from "./BrandSearchInput.module.css";

const BrandSearchInput = ({
	fetchData,
	clearData,
	options,
	onSelect,
	placeholder,
	name,
	className,
	label,
	autoComplete = false,
	valid = true,
	defaultLiText,
	defaultLiOnClick,
	emptyResult = "Ничего не найдено",
	value,
}) => {
	const [searchTerm, setSearchTerm] = useState(value || "");
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
	const [showDropdown, setShowDropdown] = useState(false);
	const inputRef = useRef(null); // Создаем реф для контейнера

	useEffect(() => {
		if (debouncedSearchTerm.length >= 3) {
			fetchData(debouncedSearchTerm);
		} else {
			clearData();
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		setSearchTerm(value || "");
	}, [value]);

	const debouncedSearch = useCallback(
		debounce((term) => setDebouncedSearchTerm(term), 300),
		[]
	);

	const handleInputChange = (event) => {
		const newValue = event.target.value;
		if (newValue === "") setShowDropdown(false);

		setSearchTerm(newValue);
		debouncedSearch(newValue);
		setShowDropdown(true);
	};

	const handleSelectOption = (option) => {
		onSelect(option);
		setSearchTerm(option[name]);
		setShowDropdown(false);
	};

	const handleClickOutside = (event) => {
		if (inputRef.current && !inputRef.current.contains(event.target)) {
			setShowDropdown(false);
		}
	};

	const hanleInputClick = () => {
		if (searchTerm.length >= 3) {
			setShowDropdown(true);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div ref={inputRef} className={`${className || ""}`}>
			{label && (
				<label
					htmlFor={name}
					className={`text-sm-medium ${classes.label} 
				${!valid ? classes.error : ""}`}
				>
					{label}
				</label>
			)}
			<input
				type="text"
				className={`text-md-medium ${classes.searchInput}`}
				placeholder={placeholder || "Введите значение"}
				value={searchTerm.name ? searchTerm.name : searchTerm}
				onChange={handleInputChange}
				name={name}
				autoComplete={autoComplete}
				onClick={hanleInputClick}
			/>
			{searchTerm && (
				<ArrowCircle
					className={classes.openArrow}
					onClick={() =>
						window.open(
							`https://www.instagram.com/${searchTerm.name ? searchTerm.name : searchTerm}`,
							"_blank"
						)
					}
				/>
			)}
			{showDropdown && searchTerm.length >= 3 && (
				<ul className={classes.dropdown}>
					{options?.length > 0 ? (
						options.map((option) => (
							<li
								key={option.id}
								onClick={() => handleSelectOption(option)}
								className={`text-md-medium ${classes.dropdownItem}`}
							>
								{option.name}
							</li>
						))
					) : (
						<li className={`text-md-medium ${classes.dropdownItem} ${classes.emptyLi}`} disabled>
							{emptyResult}
						</li>
					)}

					<li
						className={`text-md-medium ${classes.dropdownItem} ${classes.defaultLi}`}
						onClick={defaultLiOnClick}
					>
						{defaultLiText}
					</li>
				</ul>
			)}
		</div>
	);
};

export default BrandSearchInput;
