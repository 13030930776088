import React from "react";
import BrandButton from "@ui/Button/BrandButton";
import PageTitle from "@ui/PageTitle/PageTitle";
import classes from "./CommentList.module.css";
import useModalStore from "@store/ModalStore";

export const CommentList = ({ comments }) => {
    const { hideModal } = useModalStore();
    
    return (
        <div className={classes.wrapper}>
            <PageTitle title="Как исполнитель увидит комменты" />
            <p className="text-sm-medium" style={{ color: "#475467" }}>
                Каждая новая строка — новый комментарий
            </p>
            <div className={classes.commentListContainer}>
                <ul className={classes.list}>
                    {comments.map((comment, index) => {
                        return (
                            <li key={`${comment}${index}`} className={`text-md-regular ${classes.item}`}>
                                {comment}
                            </li>
                        );
                    })}
                </ul>
            </div>
            <BrandButton className={`btn-brand-xl w-100`} onClick={hideModal}>
                Понятно
            </BrandButton>
        </div>
    );
};
