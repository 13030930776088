import ContractorsTable from "@components/ContractorsTable/ContractorsTable";
import PaymentSuccess from "@components/Modals/PaymentSuccess/PaymentSuccess";
import ShowMore from "@components/ShowMore/ShowMore";
import BrandButton from "@components/ui/Button/BrandButton";
import useAuthStore from "@store/AuthStore";
import useModalStore from "@store/ModalStore";
import useTransactionStore from "@store/TransactionStore";
import useUserStore from "@store/UserStore";
import PageTitle from "@ui/PageTitle/PageTitle";
import useHeaderStore from "@store/HeaderStore";
import React, { useEffect, useState } from "react";
import classes from "./ContractorsPage.module.css";

const ContractorsPage = () => {
	const { getUserList, userList, setUserList } = useUserStore();
	const { isOperationalDirector } = useAuthStore();
	const { balanceDebiting } = useTransactionStore();
	const { showModal } = useModalStore();
	const { isHeaderFixed } = useHeaderStore();

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const limit = 10;

	const sumBalance = () => {
		let sum = 0;
		selectedUsers.forEach((user) => {
			if (user.wallet?.balance) {
				sum += user?.wallet?.balance;
			}
		});
		return sum ? `${sum} ₽` : "";
	};

	const setDefaultParams = () => {
		setUserList([]);
		setSelectedUsers([]);
		setTotalCount(0);
		setCurrentPage(1);
	};

	const successPayment = () => {
		setDefaultParams();
		getUserList({ page: 1, limit, successCb: (data) => setTotalCount(data.total) });
		showModal(<PaymentSuccess />);
	};

	const payContactors = () => {
		let user_ids = selectedUsers.map((user) => user.id);
		balanceDebiting({ user_ids, successCb: successPayment });
	};

	const setMoreUsers = (newUsers) => {
		setTotalCount(newUsers.total);

		setUserList([...userList, ...newUsers.items]);
	};

	useEffect(() => {
		getUserList({ page: currentPage, limit, successCb: setMoreUsers });
	}, [currentPage]);

	useEffect(() => {
		return () => setDefaultParams();
	}, []);

	const payButtonRender = () => {
		return (
			<>
				{isOperationalDirector() && sumBalance() && (
					<BrandButton
						className={`btn-brand-non-outline ${classes.payButton}`}
						onClick={payContactors}
					>
						{selectedUsers.length === userList.length && !sumBalance()
							? `Оплачено`
							: `Оплатить ${sumBalance()}`}
					</BrandButton>
				)}
			</>
		);
	};

	return (
		<>
			<div className={classes.pageHeader}>
				{!isHeaderFixed && <PageTitle title="Исполнители" className={classes.title} />}
				{payButtonRender()}
			</div>
			<ContractorsTable
				userList={userList}
				selectedUsers={selectedUsers}
				setSelectedUsers={setSelectedUsers}
			/>
			<div className={classes.container}>
				{totalCount > userList.length && (
					<ShowMore
						data={userList}
						setCurrentPage={setCurrentPage}
						totalCount={totalCount}
						className={classes.moreButton}
					/>
				)}
			</div>
		</>
	);
};

export default ContractorsPage;
