import React from "react";
import { Dropdown } from "react-bootstrap";
import BrandDropdownMenu from "./BrandDropdownMenu";

const BrandDropdown = ({ triggerComponent, dropdownMenu, dropdownMenuClassName, showDropdown }) => {
	return (
		<Dropdown show={showDropdown}>
			{triggerComponent}

			<BrandDropdownMenu dropdownMenu={dropdownMenu} className={dropdownMenuClassName} />
		</Dropdown>
	);
};

export default BrandDropdown;
