import useReportStore from "@store/ReportStore";
import React from "react";
import ReportItem from "../ReportItem/ReportItem";
import classes from "./ReportList.module.css";

const ReportList = ({ data }) => {
	const { downloadWithdrawalReport } = useReportStore();

	if (!data.length) return <p className="text-sm-medium m-5">Выплат не было</p>;

	return (
		<div className={classes.wrapper}>
			<a className="d-none" id="dowload-link">
				download
			</a>
			{data.map((report) => (
				<ReportItem
					item={report}
					key={report.id}
					onClick={() => downloadWithdrawalReport({ id: report.id })}
				/>
			))}
		</div>
	);
};

export default ReportList;
