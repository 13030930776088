import { LOGIN_WITH_TOKEN } from "@/const/token";
import ConnectWebSocket from "@components/ConnectWebSocket/ConnectWebSocket";
import { isSignInPage, isStartPage, isHomePage } from "@const/url";
import IsValidToken from "@hooks/IsValidToken";
import useRouter from "@hooks/useRouter";
import useAuthStore from "@store/AuthStore";
import React, { useEffect } from "react";
import AppLayout from "./AppLayout";

function App() {
	const { isWebSocketConnected, isAuth, login, logout } = useAuthStore();
	const { navigate, location } = useRouter();
	const isValidToken = IsValidToken();

	useEffect(() => {
		if (!isWebSocketConnected) return;
		if (!isStartPage()) localStorage.setItem("lastPage", location.pathname);

		if (isSignInPage() && isValidToken && isAuth) {
			navigate("/profile");
			return;
		}

		if (isValidToken && !isAuth) {
			login(navigate, isValidToken, LOGIN_WITH_TOKEN);
			return;
		}

		if (!isValidToken && isAuth) {
			logout();
			return;
		}

		if (!isStartPage() && !isValidToken && !isAuth) {
			navigate("/signin");
			return;
		}

		if (isHomePage()) {
			isValidToken && isAuth ? navigate("/profile") : navigate("/signin");
		}
	}, [isWebSocketConnected, isValidToken, isAuth, location]);

	return (
		<>
			<ConnectWebSocket />
			<AppLayout />
		</>
	);
}
export default App;
