import PasswordRecoveryForm from "@components/Forms/PasswordRecoveryForm/PasswordRecoveryForm";
import useAuthStore from "@store/AuthStore";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const PasswordRecovery = () => {
	const { passwordRecoveryConfirm } = useAuthStore();
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const user_id = searchParams.get("user_id");
	const [showForm, setShowForm] = useState(false);

	useEffect(() => {
		if (!token || !user_id) return;
		setTimeout(
			() => passwordRecoveryConfirm({ token, user_id, successCb: () => setShowForm(true) }),
			1000
		);
	}, [token, user_id]);

	return <>{showForm && <PasswordRecoveryForm token={token} />}</>;
};

export default PasswordRecovery;
