import { socket } from "@/socket";
import { BRAND_ACTIVE_ROLE, USER_ROLE } from "@const/roles";
import { TOKEN } from "@const/token";
import Cookies from "js-cookie";
import { create } from "zustand";
import useModuleStore from "./ModuleStore";
import useProfileStore from "./ProfileStore";
import useRequestStore from "./RequestStore";

const useAuthStore = create((set, get) => ({
	isWebSocketConnected: socket.connected,
	isAuth: false,
	accessToken: null,
	refreshToken: null,
	profileData: null,
	userRoles: null,
	userRole: null,

	setTokens: (access_token, refresh_token, withToken) => {
		Cookies.set(TOKEN.BRAND_ACCESS_TOKEN, access_token.token, {
			expires: new Date(access_token.exp),
			secure: true,
		});
		if (!withToken) {
			Cookies.set(TOKEN.BRAND_REFRESH_TOKEN, refresh_token.token, {
				expires: new Date(refresh_token.exp),
				secure: true,
			});
		}
		set({
			accessToken: access_token,
			refreshToken: refresh_token,
		});
	},
	removeTokens: () => {
		set({
			accessToken: null,
			refreshToken: null,
		});
		Cookies.remove(TOKEN.BRAND_ACCESS_TOKEN);
		Cookies.remove(TOKEN.BRAND_REFRESH_TOKEN);
	},

	signUpConfirm: (token) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:sign_up_confirm", {
			requestData: {
				registration_request_id: token,
			},
			successCb: (responseData) => {
				console.log("Подтверждение регистрации пользователя успешно");
			},
		});
	},
	signUpComplete: (userData, handleFormError, successCb) => {
		const { password, token, phone } = userData;
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:sign_up_complete", {
			requestData: {
				registration_request_id: token,
				phone: phone,
				password,
				is_confirmed_processing_personal_data: true,
				is_confirmed_privacy_policy: true,
				is_confirmed_public_offer_agreement: true,
			},
			successCb: (responseData) => {
				successCb();
			},
			errorCb: (error) => {
				handleFormError && handleFormError(error.title);
			},
		});
	},
	login: (navigate, userData, withToken = false, handleFormError) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		const event = withToken ? "users:sign_in_token" : "users:sign_in";
		handleRequest(event, {
			requestData: withToken ? { token: userData } : userData,
			successCb: (responseData) => {
				const { user, access_token, refresh_token } = responseData;
				if (!user.confirmed_on) {
					alert("Пользователь не подтвержден!");
					get().logout();
					return;
				}
				set({ profileData: user });
				set({ isAuth: true });

				let activeRole = Cookies.get(BRAND_ACTIVE_ROLE) || user.roles[0]?.role?.code;
				if (!user.roles.length) {
					alert("У пользователя не добавлено ни одной роли");
					get().logout();
					return;
				}
				get().setUserRoles(user.roles);
				get().setActiveRole({ role: activeRole, navigate });
				get().setTokens(access_token, refresh_token, withToken);
				const { moduleList, getModuleList } = useModuleStore.getState();

				if (!moduleList?.length) getModuleList({ page: 1, limit: 100 });
			},
			errorCb: (error) => {
				handleFormError && handleFormError(error.title);
			},
		});
	},
	logout: () => {
		const handleRequest = useRequestStore.getState().handleRequest;

		get().removeTokens();
		set({ profileData: null });
		set({ isAuth: false });
		get().setUserRoles(null);
		get().setActiveRole({ role: null });
		Cookies.remove(BRAND_ACTIVE_ROLE);
		handleRequest("users:sign_out");
		window.location.href = "/signin";
	},
	handleRedirect: (navigate, role, withLastPage = false) => {
		const redirectToLastPage = () => {
			const lastPage = localStorage.getItem("lastPage");
			if (lastPage) {
				navigate(lastPage);
				console.log("withLastPage", withLastPage);

				return true;
			}
			return false;
		};

		if (navigate) {
			if (withLastPage && redirectToLastPage()) {
				return;
			}
			const defaultPage = role === USER_ROLE.CONTRACTOR ? "/tasks" : "/orders";
			navigate(defaultPage);
		}
	},

	setActiveRole: ({ role, successCb, navigate, withLastPage = true }) => {
		set({ userRole: role });
		Cookies.set(BRAND_ACTIVE_ROLE, role, {
			expires: 7,
			secure: true,
		});
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:set_role", {
			requestData: { role_code: role },
			successCb: () => {
				successCb && successCb();
				console.log(`Роль установлена ${role}`);
				get().handleRedirect(navigate, role, withLastPage);
			},
			errorCb: (error) => {
				console.error("Не удалось установить роль:", error);
			},
		});
	},
	changeRoles: ({ user_id, roles, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:change_roles", {
			requestData: { entity_id: user_id, role_codes: roles },
			successCb: (responseData) => {
				console.log(`Роли установлены ${JSON.stringify(responseData.item)}`);
				successCb && successCb();
			},
			errorCb: (error) => {
				console.error("Не удалось изменить роли:", error);
			},
		});
	},

	setUserRoles: (roles) => {
		set({ userRoles: roles });
	},

	setIsWebSocketConnected: (value) => {
		set({ isWebSocketConnected: value });
	},
	isOperationalDirector: () => {
		return get().userRole === USER_ROLE.OPERATIONAL_DIRECTOR;
	},
	passwordRecovery: ({ email, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:password_recovery", {
			requestData: { email },
			successCb: (responseData) => {
				console.log(`Отправлено письмо`);
				successCb && successCb();
			},
			errorCb: (error) => {
				console.error("Не удалось отправить письмо", error);
			},
		});
	},
	passwordRecoveryConfirm: ({ token, user_id, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:password_recovery_confirm", {
			requestData: { token, user_id },
			successCb: (responseData) => {
				successCb && successCb();
				console.log(`Подтверждение запроса на восстановление пароля пользователя`);
			},
			errorCb: (error) => {
				console.error(
					"не удалось подтверждить запрос на восстановление пароля пользователя",
					error
				);
			},
		});
	},
	passwordRecoveryComplete: ({ token, password, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:password_recovery_complete", {
			requestData: { token, password },
			successCb: (responseData) => {
				successCb && successCb();
				console.log(`Успешная смена пароля`);
			},
			errorCb: (error) => {
				console.error("Не удалось изменить пароль", error);
			},
		});
	},
}));

export default useAuthStore;
