export const validatePassword = (password, acceptPassword) => {
	if (!password.trim()) {
		return "Без пароля не получится";
	}
	if (password.trim().length < 8) {
		return "Пароль должен содержать хотя бы 8 символов.";
	}

	if (acceptPassword) {
		if (password !== acceptPassword) {
			return "Пароли не совпадают";
		}
	}

	return null;
};
export const validateEmail = (email) => {
	if (!email.trim()) {
		return "Без почты не получится";
	}
	const isValid =
		/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(
			email
		);
	return isValid ? null : "Неверный формат почты";
};
