import React from "react";
import classes from "./TransactionItem.module.css";
import { ReactComponent as Fine } from "@assets/icons/transaction/fine.svg";
import { ReactComponent as TaskPay } from "@assets/icons/transaction/task_pay.svg";
import { ReactComponent as Withdrawal } from "@assets/icons/transaction/withdrawal.svg";
import { TRANSACTION_TYPE } from "@const/transaction";

const TRANSACTION_ICON = {
	[TRANSACTION_TYPE.FINE]: <Fine />,
	[TRANSACTION_TYPE.TASK_PAY]: <TaskPay />,
	[TRANSACTION_TYPE.WITHDRAWAL]: <Withdrawal />,
};

const calculateAmount = (amount) => {
	if (!amount) return null;
	let amountString = amount.toString();
	return amountString.includes("-") ? `${amountString.substr(1)} ₽` : `${amountString} ₽`;
};

const formatTitle = (item) => {
	const itemType = item.type;
	return itemType.code === TRANSACTION_TYPE.TASK_PAY
		? itemType.title.match(/^\S+/)[0]
		: itemType.title;
};

const TransactionItem = ({ item }) => {
	return (
		<div className={classes.wrapper}>
			<div className={classes.imgWrapper}>{TRANSACTION_ICON[item.type.code]}</div>
			<div className={classes.infoContainer}>
				<p className="text-md-medium">
					{formatTitle(item)} {calculateAmount(item.amount)}
				</p>
				<p className={`text-xs-medium ${classes.description} `}>{item.description}</p>
			</div>
		</div>
	);
};

export default TransactionItem;
