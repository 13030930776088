import useTransactionStore from "@/store/TransactionStore";
import useModalStore from "@store/ModalStore";
import useProfileStore from "@store/ProfileStore";
import BrandButton from "@ui/Button/BrandButton";
import PageTitle from "@ui/PageTitle/PageTitle";
import React, { useState } from "react";
import BrandInput from "@ui/Input/BrandInput";
import BrandTextArea from "@ui/TextArea/BrandTextArea";
import classes from "../Modals.module.css";
import { onlyNumbersValue } from "@helpers/index";

const FineContractor = ({ id }) => {
	const { hideModal } = useModalStore();
	const { balanceDebitingFine } = useTransactionStore();
	const { getOtherProfileInfo } = useProfileStore();
	const [fineSum, setFineSum] = useState("");
	const [fineReason, setFineReason] = useState("");
	const [error, setError] = useState({ sum: "", reason: "", reqError: "" });

	const successFine = () => {
		getOtherProfileInfo({ entity_id: id, successCb: () => hideModal() });
	};

	const fineHandler = () => {
		setError({ sum: "", reason: "" });

		let hasError = false;
		if (!fineSum) {
			setError((prev) => ({ ...prev, sum: "Сумма штрафа обязательна." }));
			hasError = true;
		}
		if (!fineReason) {
			setError((prev) => ({ ...prev, reason: "Причина штрафа обязательна." }));
			hasError = true;
		}

		if (hasError) return;

		balanceDebitingFine({
			user_id: id,
			amount: fineSum,
			description: fineReason,
			successCb: successFine,
			errorCb: (error) => setError((prev) => ({ ...prev, reqError: error })),
		});
	};

	return (
		<section className={classes.wrapper}>
			<PageTitle title="Штраф" />
			<BrandInput
				label="Сумма штрафа"
				placeholder="Введите сумму"
				value={fineSum}
				onChange={(v) => setFineSum(v)}
				onKeyPress={onlyNumbersValue}
				error={error.sum}
			/>
			<BrandTextArea
				placeholder="Причина штрафа"
				value={fineReason}
				onChange={(v) => setFineReason(v)}
				valid={!error.reason}
			/>

			<BrandButton className="btn-brand-xl w-100" onClick={fineHandler}>
				Оштрафовать
			</BrandButton>
			<p className="text-sm-medium text-danger">{error.reqError}</p>
		</section>
	);
};

export default FineContractor;
