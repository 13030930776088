import useHeaderStore from "@store/HeaderStore";
import React from "react";
const ReportPageHeader = () => {
	const { isHeaderFixed } = useHeaderStore();

	return (
		<div className="d-flex gap-3 align-items-center">
			{isHeaderFixed && <p className="text-md-medium">Отчеты</p>}
		</div>
	);
};

export default ReportPageHeader;
