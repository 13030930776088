import { create } from "zustand";
import useRequestStore from "./RequestStore";

const useModuleStore = create((set, get) => ({
	moduleList: null,
	getModuleList: ({ page, limit }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("modules:list", {
			requestData: { page, limit },
			successCb: (responseData) => {
				set({
					moduleList: responseData.items,
				});
			},
			errorCb: (error) => {
				console.error("Ошибка получения списка модулей:", error);
			},
		});
	},
	getSocialNetworkModule: () => {
		if (!get().moduleList?.length) return;
		return get().moduleList.filter((module) => module.code === "social_networks")[0];
	},
	getOrderModule: () => {
		if (!get().moduleList?.length) return;
		return get().moduleList.filter((module) => module.code === "orders")[0];
	},
	getOrderUserActivitiesModule: () => {
		if (!get().moduleList?.length) return;
		return get().moduleList.filter((module) => module.code === "order_user_activities")[0];
	},
	getUserModule: () => {
		if (!get().moduleList?.length) return;
		return get().moduleList.filter((module) => module.code === "users")[0];
	},
	getSocialNetworkActivitiesModule: () => {
		return get().moduleList.filter((module) => module.code === "social_network_activities")[0];
	},
	getActiveStateForModule: (module) => {
		return module.states?.filter((state) => state.code === "approved")[0].id;
	},
}));

export default useModuleStore;
