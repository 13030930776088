import Sidebar from "@components/Sidebar/Sidebar";
import classes from "./AppContainer.module.css";
import HeaderLayout from "@components/Header/Header";

const ErrorLayout = ({ children }) => {
	return (
		<div className={classes.appWrapper}>
			<HeaderLayout />
			<div className="h-100 d-flex">
				<Sidebar />
				<main className={`w-100 z-1 ${classes.errorContent}`}>{children}</main>
			</div>
		</div>
	);
};

export default ErrorLayout;
