import { ReactComponent as ChevronLeft } from "@assets/icons/button/chevronLeft.svg";
import { ReactComponent as Cross } from "@assets/icons/button/cross.svg";
import Agreement from "@components/Agreement/Agreement";
import { validateEmail, validatePassword } from "@helpers/validation";
import useRouter from "@hooks/useRouter";
import useLoaderStore from "@store/LoaderStore";
import BrandButton from "@ui/Button/BrandButton";
import BrandInput from "@ui/Input/BrandInput";
import Logo from "@ui/Logo/Logo";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import classes from "./AuthForm.module.css";
import PageTitle from "@ui/PageTitle/PageTitle";
import useAuthStore from "@store/AuthStore";

const AuthForm = ({ setFormRequestError, formRequestError, className, onSubmit }) => {
	const { navigate } = useRouter();
	const { isAppLoading } = useLoaderStore();
	const { passwordRecovery } = useAuthStore();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [formErrors, setFormErrors] = useState({});
	const [authStep, setAuthStep] = useState(0);
	const [isEditPassword, setIsEditPassword] = useState(false);

	const buttonHandler = (e) => {
		isEditPassword ? navigate("/signin") : handleNextStep(e);
	};

	const handleNextStep = (e) => {
		e.preventDefault();
		setFormRequestError(null);

		if (authStep === 0) {
			const emailError = validateEmail(email);
			if (emailError) {
				setFormErrors((prev) => ({ ...prev, email: emailError }));
			} else {
				setFormErrors((prev) => ({ ...prev, email: null }));
				setAuthStep(1);
			}
		}

		if (authStep === 1) {
			const passwordError = validatePassword(password);
			if (passwordError) {
				setFormErrors((prev) => ({ ...prev, password: passwordError }));
			} else {
				setFormErrors({});
				onSubmit({ email, password });
			}
		}
	};

	const handleBackStep = () => {
		setAuthStep((prev) => prev - 1);
		setFormErrors((prev) => ({ ...prev, password: null }));
	};

	const editPasswordHandler = () => {
		passwordRecovery({
			email,
			successCb: () => {
				setIsEditPassword(true);
				setAuthStep(3);
			},
		});
	};

	return (
		<Form className={`${classes.brandForm} ${className}`}>
			<div className={classes.logoWrapper}>
				{authStep === 0 ? (
					<Logo className={`${classes.logo}`} />
				) : (
					<BrandButton className="btn-brand-icon" onClick={handleBackStep}>
						{authStep === 1 ? <ChevronLeft /> : <Cross />}
					</BrandButton>
				)}
			</div>

			<div className={classes.inputWrapper}>
				{authStep === 3 && (
					<PageTitle
						title="
					Отправили на вашу почту ссылку для восстановления пароля"
					/>
				)}
				{authStep === 0 && (
					<>
						<h1 className={classes.title}>Введите почту</h1>
						<BrandInput
							name="email"
							type="email"
							placeholder="example@mail.ru"
							autoComplete="false"
							value={email}
							onChange={setEmail}
							error={formErrors.email || formRequestError}
							className={classes.input}
						/>
					</>
				)}

				{authStep === 1 && (
					<>
						<h1 className={classes.title}>Введите пароль</h1>
						<BrandInput
							name="password"
							type="password"
							placeholder="Пароль"
							autoComplete="true"
							value={password}
							onChange={setPassword}
							error={formErrors.password || formRequestError}
							className={classes.input}
						/>
					</>
				)}
			</div>

			<BrandButton
				className={`btn-brand-xl w-100 ${isAppLoading ? "disabled" : ""}`}
				onClick={buttonHandler}
				disabled={isAppLoading}
			>
				{isEditPassword ? "Понятно" : "Продолжить"}
			</BrandButton>

			{authStep === 0 && <Agreement />}
			{authStep === 1 && (
				<p className={classes.restorePassword} onClick={editPasswordHandler}>
					Восстановить пароль
				</p>
			)}
		</Form>
	);
};

export default AuthForm;
