import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import classes from "./BrandTextArea.module.css";

const BrandTextArea = forwardRef(
	({ value, onChange, placeholder, name, className, label, rows = 4, valid }, ref) => {
		const handleChange = (e) => {
			const newValue = e.target.value;
			onChange(newValue);
		};

		return (
			<Form.Group className={`${className || ""}`}>
				{label && (
					<Form.Label
						htmlFor={name}
						className={`text-sm-medium ${classes.label} ${!valid ? classes.error : ""}`}
					>
						{label}
					</Form.Label>
				)}
				<div className={classes.inputContainer}>
					<Form.Control
						name={name}
						as={"textarea"}
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						rows={rows}
						ref={ref}
						className={`${classes.textarea} ${!valid ? classes.textareaError : ""}`}
					/>
				</div>
			</Form.Group>
		);
	}
);

export default BrandTextArea;
