import React, { useState } from "react";
import InfoBlock from "@components/ui/InfoBlock/InfoBlock";
import classes from "./MobileCheckCard.module.css";
import { ORDER_STATUS } from "@const/order";
import KebabMenu from "@components/ui/KebabMenu/KebabMenu";
import BrandButton from "@components/ui/Button/BrandButton";
import BrandInput from "@components/ui/Input/BrandInput";
import useTaskStore from "@store/TaskStore";
import useModalStore from "@/store/ModalStore";
import AppLoader from "@components/ui/Loader/AppLoader/AppLoader";

const MobileCheckCard = ({ item, removeCheckedTask }) => {
	const { changeTaskStatus } = useTaskStore();
	const { hideModal } = useModalStore();
	const [comment, setComment] = useState({ value: "", error: false });
	const [isFullScreen, setIsFullScreen] = useState(false);

	const approveTaskHandler = () => {
		changeTaskStatus({
			id: [item.id],
			status: ORDER_STATUS.COMPLETED,
			successCb: () => {
				removeCheckedTask();
				hideModal();
			},
		});
	};

	const handleSetComment = (v) => {
		setComment((prev) => ({ ...prev, value: v, error: false }));
	};

	const cancelTaskHandler = () => {
		if (!comment.value) {
			setComment((prev) => ({ ...prev, error: true }));
			return;
		}
		setComment((prev) => ({ ...prev, error: false }));
		changeTaskStatus({
			id: item.id,
			status: ORDER_STATUS.REJECTED,
			verified_comment: comment.value,
			successCb: () => {
				removeCheckedTask();
				hideModal();
			},
		});
	};

	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};

	return (
		<div>
			<AppLoader />
			<div className={classes.screenshotContainer}>
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}${item.completed_screenshot}`}
					alt="screenshot"
					className={classes.screenshot}
					onClick={toggleFullScreen} // Click to toggle full-screen mode
				/>
			</div>

			{isFullScreen && (
				<div className={classes.fullscreenModal} onClick={toggleFullScreen}>
					<img
						src={`${process.env.REACT_APP_BACKEND_URL}${item.completed_screenshot}`}
						alt="screenshot"
						className={classes.fullscreenImage}
					/>
				</div>
			)}

			<InfoBlock>
				<p className={classes.title}>Исполнитель</p>
				<p>{item.user_social_network_account}</p>
			</InfoBlock>
			<InfoBlock>
				<p className={classes.title}>Комментарий в задании</p>
				<p>{item.order.comment}</p>
			</InfoBlock>
			{item.cancellation_comment && (
				<InfoBlock>
					<p className={classes.title}>Комментарий исполнителя</p>
					<p>{item.cancellation_comment}</p>
				</InfoBlock>
			)}
			{item.verified_comment && (
				<InfoBlock>
					<p className={classes.title}>Причина отмены задания</p>
					<p>{item.verified_comment}</p>
				</InfoBlock>
			)}
			{item.state.code === ORDER_STATUS.UNVERIFIED && (
				<KebabMenu
					mainButton={
						<BrandButton className={`${classes.end} btn-brand-xl`} onClick={approveTaskHandler}>
							Принять
						</BrandButton>
					}
					dropdown={
						<>
							<BrandInput
								placeholder="Введите причину отклонения"
								value={comment.value}
								onChange={handleSetComment}
								autoComplete="false"
								className={`${comment.error && classes.error}`}
							/>
							<BrandButton
								className={`${classes.cancel} btn-brand-outline w-100`}
								onClick={cancelTaskHandler}
							>
								Отклонить
							</BrandButton>
						</>
					}
				/>
			)}
		</div>
	);
};

export default MobileCheckCard;
