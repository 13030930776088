import TransactionBlock from "@components/ProfileBlock/ProfileMainInfo/TransactionBlock/TransactionBlock";
import BrandButton from "@components/ui/Button/BrandButton";
import InfoBlock from "@components/ui/InfoBlock/InfoBlock";
import PageTitle from "@components/ui/PageTitle/PageTitle";
import { THEME_LIST } from "@const/themes";
import { formatBalance } from "@helpers";
import { formatDate } from "@helpers/day";
import useRouter from "@hooks/useRouter";
import useThemeStore from "@store/ThemeStore";
import useModalStore from "@store/ModalStore";
import React from "react";
import classes from "./ProfileMainInfo.module.css";
import DeleteUser from "@components/Modals/DeleteUser/DeleteUser";

const ProfileMainInfo = ({ profileInfo, id }) => {
	const { getTheme, changeTheme } = useThemeStore();
	const { showModal } = useModalStore();
	const { navigate } = useRouter();

	const hasSocial = (social_code) =>
		profileInfo?.social_network_accounts.find(
			(social) => social.social_network.code === social_code
		);

	const fullBankInfo = () => {
		if (!profileInfo?.bank_account) return false;
		for (let key in profileInfo.bank_account) {
			if (!profileInfo.bank_account[key]) {
				return false;
			}
		}
		return true;
	};

	const SocialLink = ({ platform, accountId, onEdit }) => (
		<div>
			<p className={classes.subtitle}>{platform}</p>
			<div className={classes.paragraph}>
				{accountId ? (
					<a
						style={{ textDecoration: "none" }}
						target="_blank"
						className="link"
						rel="noreferrer"
						href={`https://${platform === "Инстаграм" ? "instagram.com" : "t.me"}/${accountId}`}
					>
						@{accountId}
					</a>
				) : id ? (
					<p className="text-sm-medium">Не указан</p>
				) : (
					<BrandButton className="btn-brand-non-outline" onClick={onEdit}>
						Добавить {platform.toLowerCase()}
					</BrandButton>
				)}
			</div>
		</div>
	);

	if (!profileInfo?.name) {
		return <p>Информация отсутствует</p>;
	}

	return (
		<div className={classes.wrapper}>
			<InfoBlock>
				<p className={classes.title}>Баланс</p>
				<PageTitle title={formatBalance(profileInfo?.wallet?.balance)} />
			</InfoBlock>
			<InfoBlock>
				<p className={classes.title}>Общая информация</p>
				{profileInfo.email && (
					<div>
						<p className={classes.subtitle}>Почта</p>
						<p className={classes.paragraph}>{profileInfo.email}</p>
					</div>
				)}
				{profileInfo.phone && (
					<div>
						<p className={classes.subtitle}>Телефон</p>
						<p className={classes.paragraph}>{profileInfo.phone}</p>
					</div>
				)}
				{profileInfo.confirmed_on && (
					<div>
						<p className={classes.subtitle}>Дата регистрации</p>
						<p className={classes.paragraph}>
							{formatDate({ dateString: profileInfo.confirmed_on, month: true })}
						</p>
					</div>
				)}
			</InfoBlock>

			<InfoBlock>
				<>
					<p className={classes.title}>Соцсети</p>
					<SocialLink
						platform="Инстаграм"
						accountId={hasSocial("instagram")?.account_id}
						onEdit={() => navigate("/profile/edit")}
					/>
					<SocialLink
						platform="Телеграм"
						accountId={hasSocial("telegram")?.account_id}
						onEdit={() => navigate("/profile/edit")}
					/>
				</>
			</InfoBlock>
			<InfoBlock>
				<p className={classes.title}>Задания</p>
				<div className={classes.container}>
					{profileInfo.order_activity_completed > 0 && (
						<div>
							<p className={classes.subtitle}>Принято</p>
							<p className={classes.paragraph}>{profileInfo.order_activity_completed}</p>
						</div>
					)}
					{profileInfo.order_activity_created > 0 && (
						<div>
							<p className={classes.subtitle}>Выполнено</p>
							<p className={classes.paragraph}>{profileInfo.order_activity_created}</p>
						</div>
					)}
					{!profileInfo.order_activity_created && !profileInfo.order_activity_completed && (
						<p className="text-sm-medium">Пока нет выполненных заданий</p>
					)}
				</div>
			</InfoBlock>

			<InfoBlock>
				<p className={classes.title}>Реквизиты</p>
				{fullBankInfo() ? (
					<div>
						<p className={classes.subtitle}>Банк и номер счета</p>
						<p className={classes.paragraph}>
							{profileInfo?.bank_account?.bank_title} {profileInfo?.bank_account?.account_mask}
						</p>
					</div>
				) : id ? (
					<p className="text-sm-medium">Не указаны</p>
				) : (
					<div className={classes.container}>
						<BrandButton
							className={`btn-brand-non-outline`}
							onClick={() => navigate("/profile/edit")}
						>
							Добавить
						</BrandButton>
						<p className="text-sm-medium">Без реквизитов получить выплату не получится</p>
					</div>
				)}
			</InfoBlock>
			<InfoBlock>
				<p className={classes.title}>Транзакции</p>
				<TransactionBlock id={id} />
			</InfoBlock>
			{!id && (
				<InfoBlock>
					<p className={classes.subtitle}>Тема</p>
					<div className={classes.container}>
						{THEME_LIST.map((theme) => {
							const isActiveTheme = (code) => code === getTheme();
							return (
								<div
									className={classes.themeWrapper}
									key={theme.id}
									onClick={() => changeTheme(theme.code)}
								>
									<p
										className={`${classes.theme} ${
											isActiveTheme(theme.code) ? classes.active : ""
										}`}
										style={{ background: theme.color }}
									/>
									<p
										className={`text-sm-medium ${classes.themeTitle} ${
											isActiveTheme(theme.code) ? classes.active : ""
										}`}
									>
										{theme.title}
									</p>
								</div>
							);
						})}
					</div>
				</InfoBlock>
			)}
			{!id && (
				<BrandButton
					className={`btn-brand-non-outline ${classes.deleteAccountBtn}`}
					onClick={() => showModal(<DeleteUser />)}
				>
					Удалить профиль
				</BrandButton>
			)}
		</div>
	);
};

export default ProfileMainInfo;
