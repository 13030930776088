export const isEmptyField = (field) => {
	const trimmedField = field.trim();
	return !trimmedField;
};

export const debounce = (func, delay) => {
	let timeout;
	return (...args) => {
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

export function hasMatchingRole(userRole, elementRoles) {
	return elementRoles.includes(userRole);
}

export const trimString = (str) => (str ? str.trim() : null);

export const onlyNumbersValue = (event) => {
	if (!/[0-9.]/.test(event.key)) {
		event.preventDefault();
	}
};

export const formatBalance = (balance) => {
	return balance ? `${balance} ₽` : "0 ₽";
};
