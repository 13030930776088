import React, { useEffect, useRef, useState } from "react";
import BrandButton from "@components/ui/Button/BrandButton";
import { ReactComponent as Dots } from "@assets/icons/button/dotsVertical.svg";
import classes from "./KebabMenu.module.css";

const KebabMenu = ({ mainButton, dropdown }) => {
	const [showMenu, setShowMenu] = useState(false);
	const menuRef = useRef(null);

	const handleDotsClick = (e) => {
		e.stopPropagation();
		setShowMenu((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setShowMenu(false);
		}
	};

	useEffect(() => {
		if (showMenu) {
			document.addEventListener("click", handleClickOutside);
		} else {
			document.removeEventListener("click", handleClickOutside);
		}
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [showMenu]);

	return (
		<>
			{showMenu ? (
				<div className={classes.menuContainer} ref={menuRef}>
					{dropdown}
				</div>
			) : (
				<div className={classes.main}>
					{mainButton}
					<BrandButton className="btn-brand-icon btn-brand-icon-xl" onClick={handleDotsClick}>
						<Dots />
					</BrandButton>
				</div>
			)}
		</>
	);
};

export default KebabMenu;
