import { ReactComponent as ChevronLeft } from "@assets/icons/button/chevronLeft.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import BrandButton from "../Button/BrandButton";
const BackButton = ({ className }) => {
	const navigate = useNavigate();

	const handleBackClick = () => {
		// const history = JSON.parse(localStorage.getItem("navigationHistory")) || [];

		// if (history.length >= 3) {
		// 	navigate(-1);
		// } else {
		// 	navigate("/profile");
		// }
		navigate(-1);
	};

	return (
		<BrandButton className={`btn-brand-icon ${className || ""}`} onClick={handleBackClick}>
			<ChevronLeft />
		</BrandButton>
	);
};

export default BackButton;
