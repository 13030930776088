import MobileBottomBar from "@components/MobileBottomBar/MobileBottomBar";
import BrandButton from "@components/ui/Button/BrandButton";
import KebabMenu from "@components/ui/KebabMenu/KebabMenu";
import { ORDER_STATUS } from "@const/order";
import useRouter from "@hooks/useRouter";
import useOrderStore from "@store/OrderStore";
import useTaskStore from "@store/TaskStore";
import React from "react";

const OrderItemMobileBottomBar = () => {
	const { params } = useRouter();
	const { id } = params;

	const { currentOrder, changeOrderStatus } = useOrderStore();
	const { taskListForCheck } = useTaskStore();

	if (taskListForCheck?.length) {
		return (
			<MobileBottomBar>
				<div id="mobile-bottom-bar"></div>
			</MobileBottomBar>
		);
	}

	const standartMenuRender = () => {
		return (
			<KebabMenu
				mainButton={
					<BrandButton
						className="btn-brand-xl"
						onClick={() =>
							changeOrderStatus({
								id,
								status: ORDER_STATUS.APPROVED,
							})
						}
					>
						В работу
					</BrandButton>
				}
				dropdown={
					<>
						<BrandButton
							className="btn-brand-outline w-100"
							onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.CANCELLED })}
						>
							Отменить
						</BrandButton>
					</>
				}
			/>
		);
	};

	return (
		<MobileBottomBar>
			<div id="mobile-bottom-bar" />
			{currentOrder?.state?.code === ORDER_STATUS.DRAFT &&
				(currentOrder.started_on ? (
					<KebabMenu
						mainButton={<BrandButton className="btn-brand-xl">Разрешить бронирование</BrandButton>}
						dropdown={
							<>
								<BrandButton
									className="btn-brand-outline w-100"
									onClick={() =>
										changeOrderStatus({
											id,
											status: ORDER_STATUS.APPROVED,
										})
									}
								>
									В работу
								</BrandButton>
								<BrandButton
									className="btn-brand-outline w-100"
									onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.CANCELLED })}
								>
									Отменить
								</BrandButton>
							</>
						}
					/>
				) : (
					standartMenuRender()
				))}

			{currentOrder?.state?.code === ORDER_STATUS.PENDING && standartMenuRender()}

			{currentOrder?.state?.code === ORDER_STATUS.APPROVED && (
				<BrandButton
					className="btn-brand-xl w-100"
					onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNVERIFIED })}
				>
					На проверку
				</BrandButton>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.UNVERIFIED && (
				<KebabMenu
					mainButton={
						<BrandButton
							className="btn-brand-xl"
							onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.COMPLETED })}
						>
							Выполнен
						</BrandButton>
					}
					dropdown={
						<>
							<BrandButton
								className="btn-brand-outline w-100"
								onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.ANNULED })}
							>
								Аннулирован
							</BrandButton>
							<BrandButton
								className="btn-brand-outline w-100"
								onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNCOMPLETED })}
							>
								Выполнен частично
							</BrandButton>
						</>
					}
				/>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.EXPIRED && (
				<BrandButton
					className="btn-brand-xl w-100"
					onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNVERIFIED })}
				>
					На проверку
				</BrandButton>
			)}
		</MobileBottomBar>
	);
};

export default OrderItemMobileBottomBar;
