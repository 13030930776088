import { ReactComponent as Withdrawal } from "@assets/icons/transaction/withdrawal.svg";
import React from "react";
import classes from "./ReportItem.module.css";

const ReportItem = ({ item, onClick }) => {
	return (
		<div className={classes.wrapper} onClick={onClick}>
			<div className={classes.imgWrapper}>
				<Withdrawal />
			</div>
			<div className={classes.infoContainer}>
				<p className="text-md-medium">Оплата {item.amount} ₽</p>

				<p className={`text-xs-medium ${classes.description}`}>
					Количество исполнителей: {item.created_transactions}
				</p>
				<p className={`text-xs-medium ${classes.description}`}>{item.d}</p>
			</div>
		</div>
	);
};

export default ReportItem;
