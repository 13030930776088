import BrandButton from "@components/ui/Button/BrandButton";
import PageTitle from "@components/ui/PageTitle/PageTitle";
import React from "react";
import classes from "./ErrorPage.module.css";

const ErrorPage = ({ errorCode = 404, message = "Не нашли такую страницу" }) => {
	return (
		<div className={classes.container}>
			<p className={`${classes.errorCode} text-sm-medium`}>Ошибка {errorCode}</p>
			<PageTitle title={message} />
			<BrandButton to="/profile" className="btn-brand-outline mt-2">
				На главную
			</BrandButton>
		</div>
	);
};

export default ErrorPage;
