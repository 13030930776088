import HeaderLayout from "@components/Header/Header";
import Sidebar from "@components/Sidebar/Sidebar";
import BrandLink from "@components/ui/Link/BrandLink";
import { DEFAULT_THEME } from "@const/themes";
import { hasMatchingRole } from "@helpers";
import useAuthStore from "@store/AuthStore";
import useLoaderStore from "@store/LoaderStore";
import useMobileMenuStore from "@store/MobileMenuStore";
import useThemeStore from "@store/ThemeStore";
import AppLoader from "@ui/Loader/AppLoader/AppLoader";
import Logo from "@ui/Logo/Logo";
import BrandModal from "@ui/Modal/BrandModal";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { routes } from "../../routes/routes";
import classes from "../App/AppContainer.module.css";
import MobileMenu from "../MobileMenu/MobileMenu";
import UseHistory from "@components/history/UseHistory";
import BrandToast from "@ui/Toast/BrandToast";
import { isStartPage } from "@const/url";

const AppLayout = () => {
	const { isAppBlick } = useLoaderStore();
	const { isMobileMenuShow } = useMobileMenuStore();
	const { changeTheme, getTheme } = useThemeStore();
	const { userRole } = useAuthStore();

	const hasAccess = (path) => {
		const route = routes.find((route) => route.path === path.slice(1));
		return route ? hasMatchingRole(userRole, route.roles) : true;
	};

	useEffect(() => {
		const theme = getTheme();
		theme ? changeTheme(theme) : changeTheme(DEFAULT_THEME.code);
	}, []);

	const currentPath = window.location.pathname;

	return (
		<div
			className={`
				${classes.appWrapper} 
				${isStartPage() ? "container justify-content-center" : ""}
				${isAppBlick ? classes.appWrapperHidden : classes.appWrapperVisible}
			 	position-relative `}
		>
			<UseHistory />
			<AppLoader />
			<BrandToast />
			<BrandModal />

			{isMobileMenuShow && <MobileMenu />}

			{!isStartPage() &&
				(hasAccess(currentPath) ? (
					<HeaderLayout />
				) : (
					<BrandLink to="/profile" className="p-4">
						<Logo />
					</BrandLink>
				))}

			<div
				style={{ overflow: isMobileMenuShow ? "hidden" : "unset" }}
				className={`d-flex h-100 ${isStartPage() ? "justify-content-center" : ""}`}
			>
				{!isStartPage() && <Sidebar />}

				<main
					className={`w-100 z-1 ${classes.content} ${
						isStartPage() ? classes.contentStartPage : ""
					}`}
				>
					<Outlet />
				</main>
			</div>
		</div>
	);
};

export default AppLayout;
