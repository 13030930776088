export const ORDER_STATUS = {
	DRAFT: "draft",
	EXPIRED: "expired",
	APPROVED: "approved",
	UNVERIFIED: "unverified",
	UNCOMPLETED: "uncompleted",
	COMPLETED: "completed",
	CANCELLED: "cancelled",
	PENDING: "pending",
	REJECTED: "rejected",
	ANNULED: "annulled",
};

export const TASK_ACTIVITY_ID = {
	VIEW_LIVE: 5,
};
