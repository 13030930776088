import useTransactionStore from "@store/TransactionStore";
import React, { useEffect, useState } from "react";
import ShowMore from "@components/ShowMore/ShowMore";
import classes from "./TransactionBlock.module.css";
import TransactionList from "./TransactionList/TransactionList";

const TransactionBlock = ({ id }) => {
	const { getUserTransactionList, userTransactionList, setUserTransactionList } =
		useTransactionStore();
	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const limit = 5;

	const clearParams = () => {
		setCurrentPage(1);
		setTotalCount(0);
		setUserTransactionList([]);
	};
	const setMoreTransactions = (newTransactions) => {
		setTotalCount(newTransactions.total);

		setUserTransactionList([...userTransactionList, ...newTransactions.items]);
	};

	useEffect(() => {
		getUserTransactionList({ id, page: currentPage, limit: limit, successCb: setMoreTransactions });
	}, [currentPage]);

	useEffect(() => {
		clearParams();
	}, [id]);

	useEffect(() => {
		return () => clearParams();
	}, []);

	return (
		<>
			<TransactionList data={userTransactionList} />
			<div className={classes.container}>
				{totalCount > userTransactionList.length && (
					<ShowMore
						data={userTransactionList}
						setCurrentPage={setCurrentPage}
						totalCount={totalCount}
					/>
				)}
			</div>
		</>
	);
};

export default TransactionBlock;
