import { create } from "zustand";
import useRequestStore from "./RequestStore";
import useSystemStore from "./SystemStore";
import useOrderStore from "./OrderStore";
import { encryptRSA } from "@helpers/crypto";
import { trimString } from "@helpers/index";

const useProfileStore = create((set, get) => ({
	profileInfo: null,
	editedProfileData: {
		password: "",
		phone: "",
		surname: "",
		second_name: "",
		social_network_accounts: [{ social_network_id: "", account_id: "" }],
		bank_account: {
			bank_title: "",
			bank_bik: "",
			bank_ks: "",
			account: "",
		},
		validationErrors: {},
		isRequisitesChanged: false,
	},
	setIsRequisitesChanged: (value) => {
		set({ isRequisitesChanged: value });
	},
	setValidationErrors: (errors) => {
		set({ validationErrors: errors });
	},

	clearValidationErrors: () => {
		set({ validationErrors: {} });
	},
	setEditedProfileInfo: (keyPath, value) => {
		const bankKeys = [
			"bank_account.bank_title",
			"bank_account.bank_bik",
			"bank_account.bank_ks",
			"bank_account.account_mask",
		];

		if (bankKeys.includes(keyPath)) {
			get().setIsRequisitesChanged(true);
		}

		const keysWithoutAccountMask = bankKeys.slice(0, -1);

		if (keysWithoutAccountMask.includes(keyPath)) {
			get().setEditedProfileInfo("bank_account.account_mask", "");
		}

		set((state) => {
			const keys = keyPath.split(".");
			const lastKey = keys.pop();

			const nestedUpdate = (obj) => {
				if (keys.length === 0) {
					return { ...obj, [lastKey]: value };
				}

				const key = keys.shift();
				return {
					...obj,
					[key]: nestedUpdate(obj[key] || {}),
				};
			};

			return {
				profileInfo: nestedUpdate(state.profileInfo),
			};
		});
	},
	setProfileInfo: (data) => {
		set({
			profileInfo: data,
		});
	},
	getProfileInfo: () => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:profile", {
			successCb: (responseData) => {
				get().setProfileInfo(responseData.item);
			},
			errorCb: (error) => {
				console.error("Ошибка получения профиля:", error);
			},
		});
	},
	getOtherProfileInfo: ({ entity_id, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:view", {
			requestData: { entity_id },
			successCb: (responseData) => {
				successCb && successCb(responseData.item);
				get().setProfileInfo(responseData.item);
			},
			errorCb: (error) => {
				console.error("Ошибка получения профиля:", error);
			},
		});
	},
	editProfileAndBankAccountInfo: ({ profileData, bankAccountData, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		let requestCount = 0;
		const totalRequests = bankAccountData ? 2 : 1;

		const checkIfFinished = () => {
			requestCount += 1;
			if (requestCount === totalRequests) {
				get().setIsRequisitesChanged(false);
				successCb && successCb();
			}
		};

		const makeRequest = (eventName, requestData) => {
			handleRequest(eventName, {
				requestData,
				successCb: (responseData) => {
					get().setProfileInfo(responseData.item);
					console.log("Успешное редактирования профиля");
					checkIfFinished();
				},
				errorCb: (error) => {
					console.error(`Ошибка при выполнении ${eventName}:`, error);
				},
			});
		};

		makeRequest("users:profile_update", profileData);

		if (bankAccountData) {
			makeRequest("users:bank_account_update", bankAccountData);
		}
	},

	getSocialsAccount: (activeSocials) => {
		return get()
			.profileInfo.social_network_accounts.filter((account) => account.account_id)
			.map((account) => {
				const social = activeSocials.find((social) => social.code === account.social_network.code);
				return social
					? { social_network_id: social.id, account_id: account.account_id.trim() }
					: null;
			})
			.filter(Boolean);
	},

	formatBankAccount: (bankAccount, encryptedAccount) => {
		const { bank_title, bank_bik, bank_ks } = bankAccount;

		if (!bank_title && !bank_bik && !bank_ks && !bankAccount.account) {
			return null;
		}

		return {
			bank_title: trimString(bank_title),
			bank_bik: trimString(bank_bik),
			bank_ks: trimString(bank_ks),
			account: encryptedAccount,
		};
	},
	formatProfileData: (socialsData) => {
		const profileInfo = get().profileInfo;

		return {
			password: null,
			phone: trimString(profileInfo.phone),
			surname: trimString(profileInfo.surname),
			second_name: trimString(profileInfo.second_name),
			name: trimString(profileInfo.name),
			social_network_accounts: socialsData,
		};
	},
	formatAccountData: () => {
		const profileInfo = get().profileInfo;
		const systemSettings = useSystemStore.getState().systemSettings;
		const bankAccount = profileInfo.bank_account || {};
		const accountOrMask = bankAccount.account || bankAccount.account_mask;
		
		const encryptedAccount = accountOrMask
			? encryptRSA(accountOrMask.trim(), systemSettings.public_key)
			: "";
		return {
			bank_account: get().formatBankAccount(bankAccount, encryptedAccount),
		};
	},
	validateBankAccount: (bankAccount) => {
		const errors = {};

		const validateBankTitle = (bankTitle) =>
			/^[а-яА-Яa-zA-Z0-9\s\-\.]{3,255}$/.test(bankTitle.trim());
		const validateAccount = (account) => /^\d{20}$/.test(account.trim());
		const validateCorrespondentAccount = (bankKS) => /^\d{20}$/.test(bankKS.trim());
		const validateBik = (bankBik) => /^\d{9}$/.test(bankBik.trim());

		if (!validateBankTitle(bankAccount.bank_title)) {
			errors.bank_title = "Наименование банка должно содержать от 3 до 255 символов.";
		}
		if (!validateAccount(bankAccount.account || bankAccount.account_mask)) {
			errors.account = "Расчетный счет должен содержать 20 цифр.";
		}
		if (!validateCorrespondentAccount(bankAccount.bank_ks)) {
			errors.bank_ks = "Корреспондентский счет должен содержать 20 цифр.";
		}
		if (!validateBik(bankAccount.bank_bik)) {
			errors.bank_bik = "БИК должен содержать 9 цифр.";
		}

		return errors;
	},
	saveProfile: (navigate) => {
		const profileInfo = get().profileInfo || {};
		const bankAccount = profileInfo.bank_account || {};

		if (get().isRequisitesChanged) {
			const errors = get().validateBankAccount(bankAccount);

			if (Object.keys(errors).length > 0) {
				get().setValidationErrors(errors);
				return;
			}

			get().clearValidationErrors();
		}

		const getSocialNetworkList = useOrderStore.getState().getSocialNetworkList;

		getSocialNetworkList({
			page: 1,
			limit: 100,
			successCb: (data) => {
				const socialsData = get().getSocialsAccount(data.items);
				const mainAccountData = get().formatProfileData(socialsData);
				const bankAccountData = get().formatAccountData();

				get().editProfileAndBankAccountInfo({
					profileData: mainAccountData,
					bankAccountData: get().isRequisitesChanged ? bankAccountData : null,
					successCb: () => {
						navigate("/profile");
					},
				});
			},
		});
	},
}));

export default useProfileStore;
