import useModalStore from "@store/ModalStore";
import useOrderStore from "@store/OrderStore";
import BrandButton from "@ui/Button/BrandButton";
import PageTitle from "@ui/PageTitle/PageTitle";
import BrandTextArea from "@ui/TextArea/BrandTextArea";
import React, { useState } from "react";
import useLoaderStore from "@store/LoaderStore";

const AddUrlToOrder = () => {
	const { currentOrder, getOrderInfo, addUrlOrder } = useOrderStore();
	const { hideModal } = useModalStore();
	const { isAppLoading } = useLoaderStore();
	const [url, setUrl] = useState({ value: "", error: false });

	const addUrlOrderHandler = () => {
		if (!url.value || !url.value.includes("instagram.com")) {
			setUrl((prev) => ({ ...prev, error: true }));
			return;
		}

		setUrl((prev) => ({ ...prev, error: false }));

		addUrlOrder({
			id: currentOrder.id,
			url: url.value,
			successCb: () => {
				getOrderInfo(currentOrder.id);
				hideModal();
			},
		});
	};

	return (
		<div className="d-flex flex-column gap-3" style={{ width: "350px" }}>
			<PageTitle title="Добавьте ссылку на пост" />

			<BrandTextArea
				placeholder="Ссылка должна быть рабочая"
				label="Чтобы разрешить исполнителям бронировать задания"
				onChange={(v) => setUrl((prev) => ({ ...prev, value: v, error: false }))}
				value={url.value}
				valid={!url.error}
			/>
			<BrandButton
				className="btn-brand-xl w-100"
				onClick={addUrlOrderHandler}
				disabled={isAppLoading}
			>
				Добавить ссылку на пост
			</BrandButton>
		</div>
	);
};

export default AddUrlToOrder;
