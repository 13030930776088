import React, { useEffect, useState } from "react";
import BrandButton from "@ui/Button/BrandButton";
import BrandInput from "@ui/Input/BrandInput";
import classes from "./PasswordRecoveryForm.module.css";
import useLoaderStore from "@store/LoaderStore";
import Logo from "@ui/Logo/Logo";
import { Form } from "react-bootstrap";
import { validatePassword } from "@helpers/validation";
import useSystemStore from "@store/SystemStore";
import useAuthStore from "@store/AuthStore";
import useRouter from "@hooks/useRouter";
import useToastStore from "@store/ToastStore";

const PasswordRecoveryForm = ({ token }) => {
	const { isAppLoading } = useLoaderStore();
	const { getSystemSettings, systemSettings } = useSystemStore();
	const { passwordRecoveryComplete } = useAuthStore();
	const { showToast } = useToastStore();
	const { navigate } = useRouter();
	const [password, setPassword] = useState("");
	const [acceptPassword, setAcceptPassword] = useState("");
	const [formErrors, setFormErrors] = useState({});
	const [formRequestError, setFormRequestError] = useState(null);

	useEffect(() => {
		getSystemSettings();
	}, []);

	const saveNewPasswordHandler = (e) => {
		e.preventDefault();
		setFormRequestError(null);
		const passwordError = validatePassword(password, acceptPassword);
		const regExp = new RegExp(systemSettings.password_regexp);
		const regexAccess = regExp.test(password);

		if (passwordError) {
			setFormErrors((prev) => ({
				...prev,
				password: passwordError,
			}));
			return;
		}
		if (!regexAccess) {
			setFormErrors((prev) => ({
				...prev,
				password:
					"Пароль должен содержать не менее 8 символов и содержать одну строчную букву, одну заглавную букву и одну цифру",
			}));
			return;
		}

		setFormErrors({});
		passwordRecoveryComplete({
			token,
			password,
			successCb: () => {
				navigate("/signin");
				showToast("Пароль успешно изменен");
			},
		});
	};

	return (
		<div className={classes.wrapper}>
			<Form className={`${classes.brandForm}`}>
				<div className={classes.logoWrapper}>
					<Logo className={`${classes.logo}`} />
				</div>
				<h1 className={classes.title}>Придумайте новый пароль</h1>
				<BrandInput
					name="password"
					type="password"
					placeholder="Пароль"
					autoComplete="false"
					value={password}
					onChange={setPassword}
					error={formErrors.password}
					className={classes.input}
				/>
				<BrandInput
					name="password"
					type="password"
					placeholder="Подтвердите пароль"
					autoComplete="false"
					value={acceptPassword}
					onChange={setAcceptPassword}
					error={formErrors.acceptPassword}
					className={classes.input}
					showPasswordClue={false}
				/>
				<BrandButton
					type="button"
					className={`btn-brand-xl w-100 ${isAppLoading ? "disabled" : ""}`}
					disabled={isAppLoading}
					onClick={saveNewPasswordHandler}
				>
					Продолжить
				</BrandButton>
				<p className={classes.error}>{formRequestError}</p>
			</Form>
		</div>
	);
};

export default PasswordRecoveryForm;
