import React, { useEffect } from "react";
import ButtonContainer from "./ButtonContainer/ButtonContainer";
import classes from "./CheckBlock.module.css";
import Screenshots from "./Screenshots/Screenshots";
import { createPortal } from "@helpers/portal";

const CheckBlock = ({
	taskListForCheck,
	setShowCancelledBlock,
	removeCheckedTask,
	showCancelBlockInModal,
}) => {
	const lastTask = taskListForCheck[taskListForCheck?.length - 1];
	const otherTasks = taskListForCheck?.slice(0, taskListForCheck?.length - 1);

	const displayedTasks = lastTask ? [lastTask, ...otherTasks].slice(0, 3).reverse() : [];

	useEffect(() => {
		setShowCancelledBlock(false);
	}, [taskListForCheck]);

	if (!taskListForCheck?.length) return;

	return (
		<div className={classes.checkBlock}>
			<Screenshots displayedTasks={displayedTasks} />
			{createPortal(
				<ButtonContainer
					removeCheckedTask={removeCheckedTask}
					setShowCancelledBlock={setShowCancelledBlock}
					showCancelBlockInModal={showCancelBlockInModal}
					className={classes.buttonContainer}
				/>,
				"mobile-bottom-bar"
			)}
			<ButtonContainer
				removeCheckedTask={removeCheckedTask}
				setShowCancelledBlock={setShowCancelledBlock}
			/>
		</div>
	);
};

export default CheckBlock;
