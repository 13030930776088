import { ReactComponent as Pencil } from "@assets/icons/button/pencil.svg";
import OrderSum from "@components/Forms/CreateOrderForm/OrderSum/OrderSum";
import AddUrlToOrder from "@components/Modals/AddUrlToOrder/AddUrlToOrder";
import TaskCheckContainer from "@components/TaskCheckContainer/TaskCheckContainer";
import BrandAccordion from "@components/ui/Accordion/BrandAccordion";
import BrandButton from "@components/ui/Button/BrandButton";
import InfoBlock from "@components/ui/InfoBlock/InfoBlock";
import List from "@components/ui/List/List";
import { ListItem } from "@components/ui/ListItem/ListItem";
import { ORDER_COLORS } from "@const/colors";
import { ORDER_STATUS } from "@const/order";
import { TASK_TYPE } from "@const/tasks";
import { convertISO8601Duration, formatDate } from "@helpers/day";
import useRouter from "@hooks/useRouter";
import useModalStore from "@store/ModalStore";
import useOrderStore from "@store/OrderStore";
import useTaskStore from "@store/TaskStore";
import React, { useEffect, useState } from "react";
import OrderItemMobileBottomBar from "./mobileBottomBar/OrderItemMobileBottomBar";
import PageTitle from "@ui/PageTitle/PageTitle";
import classes from "./OrderItem.module.css";

const OrderItem = () => {
	const { params } = useRouter();
	const { id } = params;
	const { currentOrder, getOrderInfo, getActivityComments } = useOrderStore();
	const { setTaskListForCheck } = useTaskStore();
	const { showModal, hideModal } = useModalStore();

	const [showPendingNotification, setShowPendingNotification] = useState(true);
	const [activityCommentList, setActivityCommentList] = useState([]);

	useEffect(() => {
		if (!id) return;
		getOrderInfo(id);
	}, [id]);

	useEffect(() => {
		return () => {
			setTaskListForCheck([]);
			hideModal();
		};
	}, []);

	const TaskItem = ({ title, quantity, quantity_completed, labelClass }) => (
		<div className="col-md-3 col-sm-4 col-12">
			<p className={`text-sm-medium ${labelClass}`}>{title}</p>
			<p className="text-md-medium">
				{currentOrder.started_on &&
				currentOrder.state.code !== ORDER_STATUS.PENDING &&
				Number.isInteger(quantity_completed)
					? `${quantity_completed} / ${quantity}`
					: `${quantity}`}
			</p>
		</div>
	);

	useEffect(() => {
		if (!currentOrder) return;
		setActivityCommentList(getActivityComments());
	}, [currentOrder]);

	const comments = [
		{
			title: "Описание комментов",
			body: (
				<List type="ordered" className="py-0">
					{activityCommentList?.length > 0 ? (
						activityCommentList.map((el) => (
							<ListItem key={el} value={el} className={`text-md-regular ${classes.listItem}`} />
						))
					) : (
						<p className="text-md-regular">
							Ознакомьтесь с контентом и оставьте комментарий по теме поста
						</p>
					)}
				</List>
			),
		},
	];

	if (!currentOrder) return <PageTitle title="Заказа не существует" />;

	return (
		<div className={classes.pageContainer}>
			{currentOrder && (
				<div className={classes.orderInfo}>
					<div className={classes.header}>
						<p className="text-sm-medium" style={{ color: ORDER_COLORS[currentOrder.state.code] }}>
							{currentOrder.state.title}
						</p>
						<div className="d-flex justify-content-between align-items-center">
							<h1>
								Заказ <span className={classes.label}>№{currentOrder.number}</span>
							</h1>
							{currentOrder.state.code === ORDER_STATUS.DRAFT && (
								<BrandButton className="btn-brand-icon" to={`/orders/edit/${id}`}>
									<Pencil />
								</BrandButton>
							)}
						</div>
						{currentOrder.state.code === ORDER_STATUS.PENDING && showPendingNotification && (
							<InfoBlock className={classes.orderPendingNotification}>
								<p className="text-sm-medium">Заказ запустится автоматически</p>
								<p className={`text-sm-medium ${classes.label}`}>
									{formatDate({ dateString: currentOrder.started_on, withTime: true, month: true })}
								</p>
								<BrandButton
									onClick={() => {
										setShowPendingNotification(false);
									}}
									className="btn-brand-non-outline"
								>
									Понятно
								</BrandButton>
							</InfoBlock>
						)}
					</div>

					<OrderSum activeTasks={currentOrder.order_activities} />

					<InfoBlock>
						<div>
							<p className={`text-sm-medium ${classes.label}`}>Заказчик</p>
							<a
								href={`https://${currentOrder.social_network.code}.com/${currentOrder.customer.name}`}
								target="_blank"
								rel="noreferrer"
							>
								{currentOrder.customer.name}
							</a>
						</div>
						{currentOrder.started_on && (
							<div>
								<p className={`text-sm-medium ${classes.label}`}>Дата старта</p>
								<p className="text-sm-medium">
									{formatDate({ dateString: currentOrder.started_on, month: true, withTime: true })}
								</p>
							</div>
						)}
						{currentOrder.finished_on ? (
							<div>
								<p className={`text-sm-medium ${classes.label}`}>Срок выполнения</p>
								<p className="text-sm-medium">
									до{" "}
									{formatDate({
										dateString: currentOrder.finished_on,
										month: true,
										withTime: true,
									})}
								</p>
							</div>
						) : (
							currentOrder.completion_time && (
								<div>
									<p className={`text-sm-medium ${classes.label}`}>Срок выполнения</p>
									<p className="text-sm-medium">
										{convertISO8601Duration({ isoString: currentOrder.completion_time })} после
										запуска
									</p>
								</div>
							)
						)}
						<div>
							<p className={`text-sm-medium ${classes.label}`}>Описание</p>
							<p>{currentOrder.comment}</p>
						</div>
						{currentOrder?.url ? (
							<BrandButton to={currentOrder?.url} target="_blank" className="btn-brand-non-outline">
								Ссылка на пост
							</BrandButton>
						) : (
							(currentOrder.state.code === ORDER_STATUS.PENDING ||
								currentOrder.state.code === ORDER_STATUS.DRAFT) && (
								<div className={classes.nonUrlBlock}>
									<BrandButton
										className="btn-brand-non-outline"
										onClick={() => showModal(<AddUrlToOrder />)}
									>
										Добавьте ссылку на пост
									</BrandButton>
									<p className={`${classes.label} text-sm-medium`}>
										Без ссылки заказ доступен для броней, но не будет запущен в работу
									</p>
								</div>
							)
						)}
					</InfoBlock>
					<InfoBlock>
						{currentOrder.started_on ? (
							<p className="text-sm-medium">Заданий принято</p>
						) : (
							<p className="text-sm-medium">Задания</p>
						)}

						<div className={classes.taskWrapper}>
							{currentOrder.order_activities?.length > 0 &&
								currentOrder.order_activities.map((task) => {
									const { activity, additional_completion_time } = task.social_network_activity;
									const title = activity.title;
									const quantity = task.quantity;
									const quantity_completed = task.quantity_completed;

									if (activity.code === TASK_TYPE.VIEW_LIVE) {
										return (
											<React.Fragment key={task.id}>
												<TaskItem
													title="Продолжительность эфира"
													quantity={convertISO8601Duration({
														isoString: additional_completion_time,
													})}
													labelClass={classes.label}
													quantity_completed={null}
												/>
												<TaskItem
													title={title}
													quantity={quantity}
													quantity_completed={quantity_completed}
													labelClass={classes.label}
												/>
											</React.Fragment>
										);
									}

									return (
										<TaskItem
											key={task.id}
											title={title}
											quantity={quantity}
											quantity_completed={quantity_completed}
											labelClass={classes.label}
										/>
									);
								})}
						</div>
						<BrandAccordion content={comments} />
					</InfoBlock>
					<OrderItemMobileBottomBar />
				</div>
			)}
			<TaskCheckContainer orderId={id} />
		</div>
	);
};

export default OrderItem;
