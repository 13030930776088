import BrandButton from "@components/ui/Button/BrandButton";
import React from "react";
import classes from "./ShowMore.module.css";
const ShowMore = ({ setCurrentPage, data, totalCount, className }) => {
	const showMore = () => {
		if (data.length < totalCount) {
			setCurrentPage((prev) => prev + 1);
		}
	};

	return (
		<>
			<BrandButton
				className={`btn-brand-outline ${classes.moreButton} ${className || ""}`}
				onClick={showMore}
			>
				Показать еще
			</BrandButton>
		</>
	);
};

export default ShowMore;
