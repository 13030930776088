export const THEMES_TYPE = {
	PURPLE: "purple",
	TURQUOISE: "turquoise",
	ROSE: "rose",
	ORANGE: "orange",
	GRAY: "gray",
};

export const THEME_LIST = [
	{
		id: 1,
		title: "Лиловая",
		code: THEMES_TYPE.PURPLE,
		color: "#F4EBFF",
	},
	{
		id: 2,
		title: "Бирюзовая",
		code: THEMES_TYPE.TURQUOISE,
		color: "#CCFBEF",
	},
	{
		id: 3,
		title: "Розовая",
		code: THEMES_TYPE.ROSE,
		color: "#FFE4E8",
	},
	{
		id: 4,
		title: "Кремовая",
		code: THEMES_TYPE.ORANGE,
		color: "#FDEAD7",
	},
	{
		id: 5,
		title: "Серая",
		code: THEMES_TYPE.GRAY,
		color: "#F4F4F5",
	},
];

export const DEFAULT_THEME = THEME_LIST[0];
