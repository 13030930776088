import { isStartPage } from "@const/url";
import { hasMatchingRole } from "@helpers";
import IsValidToken from "@hooks/IsValidToken";
import useRouter from "@hooks/useRouter";
import ErrorPage from "@pages/ErrorPage/ErrorPage";
import useAuthStore from "@store/AuthStore";
import { useEffect, useState } from "react";

const ProtectedRoute = ({ page, roles = [] }) => {
	const { isWebSocketConnected, logout, isAuth, userRole } = useAuthStore();
	const { navigate, location } = useRouter();
	const isValidToken = IsValidToken();
	const [isAccessible, setIsAccessible] = useState(false);

	useEffect(() => {
		if (isStartPage()) {
			setIsAccessible(true);
			return;
		}

		if (roles.length === 0 || hasMatchingRole(userRole, roles)) {
			setIsAccessible(true);
		} else {
			setIsAccessible(false);
		}
	}, [isValidToken, isAuth, userRole, roles, logout, navigate, location, isWebSocketConnected]);

	if (!isAccessible) {
		return (
			<ErrorPage
				errorCode={403}
				message="У вас нет доступа к этой странице. Попробуйте изменить роль."
			/>
		);
	}

	return page;
};

export default ProtectedRoute;
