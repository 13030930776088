import { USER_ROLE } from "@const/roles";
import useAuthStore from "@store/AuthStore";
import useHeaderStore from "@store/HeaderStore";
import useProfileStore from "@store/ProfileStore";
import BackButton from "@ui/BackButton/BackButton";
import React from "react";
import { BlockButton, FineButton, PayButton } from "../Buttons/Buttons";

const OtherProfilePageHeader = () => {
	const { isHeaderFixed } = useHeaderStore();
	const { userRole } = useAuthStore();
	const { profileInfo } = useProfileStore();

	return (
		<>
			<BackButton className="mobile-hide" />
			{isHeaderFixed && (
				<div className="mx-3">
					<p className="text-md-medium">
						{profileInfo?.name || ""} {profileInfo?.surname || ""}
					</p>
					<p className="text-md-medium">{profileInfo?.wallet?.balance || 0} ₽ </p>
				</div>
			)}
			<div className="ms-auto d-flex gap-4">
				{userRole === USER_ROLE.OPERATIONAL_DIRECTOR && (
					<>
						<PayButton className="mobile-hide" />
						<FineButton className="mobile-hide" />
						<BlockButton className="mobile-hide" />
					</>
				)}
				{userRole === USER_ROLE.ADMINISTRATOR && <BlockButton />}
			</div>
		</>
	);
};

export default OtherProfilePageHeader;
