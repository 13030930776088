import MobileBottomBar from "@components/MobileBottomBar/MobileBottomBar";
import BrandButton from "@components/ui/Button/BrandButton";
import KebabMenu from "@components/ui/KebabMenu/KebabMenu";
import { USER_ROLE } from "@const/roles";
import useRouter from "@hooks/useRouter";
import useAuthStore from "@store/AuthStore";
import React from "react";
import { BlockButton, FineButton, PayButton } from "../Buttons/Buttons";

const ProfilePageMobileBottomBar = ({ id }) => {
	const { navigate } = useRouter();
	const { logout, userRole } = useAuthStore();

	return (
		<MobileBottomBar>
			{id && userRole === USER_ROLE.OPERATIONAL_DIRECTOR && (
				<KebabMenu
					mainButton={<PayButton />}
					dropdown={
						<>
							<FineButton className="w-100" />
							<BlockButton className="w-100" />
						</>
					}
				/>
			)}
			{id && userRole === USER_ROLE.ADMINISTRATOR && <BlockButton />}
			{!id && (
				<KebabMenu
					mainButton={
						<BrandButton className="btn-brand-xl" onClick={() => navigate("/profile/edit")}>
							Редактировать
						</BrandButton>
					}
					dropdown={
						<>
							<BrandButton className="btn-brand-outline w-100">Сбросить пароль</BrandButton>
							<BrandButton className="btn-brand-outline w-100" onClick={logout}>
								Выйти
							</BrandButton>
						</>
					}
				/>
			)}
		</MobileBottomBar>
	);
};

export default ProfilePageMobileBottomBar;
