import React, { useMemo } from "react";
import { ORDER_COLORS } from "@const/colors";
import classes from "./OrderTaskListTable.module.css";
import BrandCheckbox from "@ui/Checkbox/BrandCheckbox";
import { ORDER_STATUS } from "@const/order";
import BrandRadio from "@ui/Radio/BrandRadio";
import useModalStore from "@store/ModalStore";
import MobileCheckCard from "../MobileCheckCard/MobileCheckCard";

const OrderTaskListTable = ({
	orderTaskList,
	taskListForCheck,
	unverifiedTaskList,
	checkTaskHandler,
	setTaskListForCheck,
	removeCheckedTask,
}) => {
	const { showModal } = useModalStore();
	const columnData = [
		{
			accessor: "order_activity.social_network_activity.activity.title",
			header: "Задание",
			elem: "order.number",
		},
		{ accessor: "state.title", header: "Статус" },
		{ accessor: "user_social_network_account", header: "Исполнитель" },
	];

	const renderElement = (cellValue, row, columnId) => {
		const isStatusColumn = columnId === "state.title";
		const getRowColor = (row) => ORDER_COLORS[row.state.code];

		return (
			<div className="text-sm-medium">
				<div
					style={{
						color: isStatusColumn ? getRowColor(row) : "",
						textDecoration: "none",
					}}
				>
					{cellValue}
					{isStatusColumn && row.verified_comment && (
						<p className={`text-sm-medium ${classes.cancelledComment}`}>
							<span>&#8223;</span> {row.verified_comment}
						</p>
					)}
					{isStatusColumn && row.cancellation_comment && (
						<p className={`text-sm-medium ${classes.cancelledComment}`}>
							<span>&#8223;</span> {row.cancellation_comment}
						</p>
					)}
				</div>
			</div>
		);
	};

	const columns = useMemo(() => {
		return columnData.map(({ accessor, header }) => {
			return {
				accessorFn: (row) =>
					accessor.split(".").reduce((obj, key) => (obj ? obj[key] : undefined), row),
				header,
				id: accessor,
			};
		});
	}, []);

	const selectAll = () => {
		if (taskListForCheck?.length === unverifiedTaskList.length) {
			setTaskListForCheck([]);
			return;
		}
		setTaskListForCheck(unverifiedTaskList);
	};

	const isChecked = (id) => {
		return taskListForCheck?.find((el) => el.id === id);
	};

	const handleRadioChange = (row) => {
		setTaskListForCheck([row]);
	};

	const rowClick = (row) => {
		if (window.innerWidth < 768) {
			showModal(
				<MobileCheckCard item={row} removeCheckedTask={removeCheckedTask} />,
				"mobileCheckCard"
			);
			return;
		}
		const code = row.state.code;
		if (code === ORDER_STATUS.APPROVED || code === ORDER_STATUS.PENDING) return;
		code === ORDER_STATUS.UNVERIFIED ? checkTaskHandler(row) : handleRadioChange(row);
	};

	return (
		<div className={classes.tableWrapper}>
			<table className={`tableStandart ${classes.table}`}>
				<thead className="thead">
					<th className={`th ${classes.checkbox}`}>
						{unverifiedTaskList?.length > 0 && (
							<BrandCheckbox
								checked={taskListForCheck?.length === unverifiedTaskList.length}
								onChange={selectAll}
							/>
						)}
					</th>
					{columns.map((col) => (
						<th className="th" key={col.id}>
							{col.header}
						</th>
					))}
				</thead>
				<tbody className="tbody">
					{orderTaskList.map((row, rowIndex) => {
						const code = row.state.code;
						const inWork = code === ORDER_STATUS.APPROVED;
						const unverified = code === ORDER_STATUS.UNVERIFIED;
						const pending = code === ORDER_STATUS.PENDING;
						const cancelled = code === ORDER_STATUS.CANCELLED;
						return (
							<tr key={rowIndex} className="tr" onClick={() => rowClick(row)}>
								<td className={`td ${classes.checkbox}`}>
									{unverified && !inWork ? (
										<BrandCheckbox
											checked={isChecked(row.id)}
											onClick={(e) => e.stopPropagation()}
											onChange={() => checkTaskHandler(row)}
										/>
									) : (
										!inWork &&
										!pending &&
										!cancelled && (
											<BrandRadio
												checked={isChecked(row.id)}
												name="task-radio"
												onClick={(e) => e.stopPropagation()}
												onChange={() => handleRadioChange(row)}
											/>
										)
									)}
								</td>
								{columns.map((col) => {
									const cellValue = col.accessorFn(row);
									return (
										<td key={col.id} className="td">
											{renderElement(cellValue, row, col.id)}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default OrderTaskListTable;
