import { ReactComponent as Contractors } from "@assets/icons/sidebar/contractors.svg";
import { ReactComponent as Main } from "@assets/icons/sidebar/main.svg";
import { ReactComponent as Reports } from "@assets/icons/sidebar/reports.svg";
import { ReactComponent as LastTasks } from "@assets/icons/sidebar/lastTasks.svg";
import { ReactComponent as Notifications } from "@assets/icons/sidebar/notifications.svg";
import { USER_ROLE } from "@const/roles";

export const HEADER_MENU = {
	ADMINISTRATOR_HEADER_LINKS: [
		{
			id: 1,
			to: "/orders",
			img: <Main />,
			title: "Все заказы",
		},
		{
			id: 2,
			to: "/contractors",
			img: <Contractors />,
			title: "Исполнители",
		},
	],
	MANAGER_HEADER_LINKS: [
		{
			id: 1,
			to: "/orders",
			img: <Main />,
			title: "Все заказы",
		},

		{
			id: 2,
			to: "/reports",
			img: <Reports />,
			title: "Отчеты",
		},
		{
			id: 3,
			to: "/contractors",
			img: <Contractors />,
			title: "Исполнители",
		},
		{
			id: 4,
			to: "/notifications",
			img: <Notifications />,
			title: "Уведомления",
		},
	],

	CONTRACTOR_HEADER_LINKS: [
		{
			id: 1,
			to: "/tasks",
			img: <Main />,
			title: "Все задания",
		},
		{
			id: 2,
			to: "/tasks_history",
			img: <LastTasks />,
			title: "Прошлые задания",
		},
		{
			id: 3,
			to: "/notifications",
			img: <Notifications />,
			title: "Уведомления",
		},
	],
};

const USER_ROLE_MAIN_PAGES = {
	ORDERS: "/orders",
	TASKS: "/tasks",
};

export const ROLE_MAIN_PAGE = {
	[USER_ROLE.MANAGER]: USER_ROLE_MAIN_PAGES.ORDERS,
	[USER_ROLE.OPERATIONAL_DIRECTOR]: USER_ROLE_MAIN_PAGES.ORDERS,
	[USER_ROLE.ADMINISTRATOR]: USER_ROLE_MAIN_PAGES.ORDERS,
	[USER_ROLE.CONTRACTOR]: USER_ROLE_MAIN_PAGES.TASKS,
};
