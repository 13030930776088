import BrandButton from "@components/ui/Button/BrandButton";
import BrandInput from "@components/ui/Input/BrandInput";
import { ORDER_STATUS } from "@const/order";
import useLoaderStore from "@store/LoaderStore";
import useTaskStore from "@store/TaskStore";
import React, { useState } from "react";
import classes from "./TaskBottomBar.module.css";
import useModalStore from "@store/ModalStore";
import useRouter from "@hooks/useRouter";
import KebabMenu from "@components/ui/KebabMenu/KebabMenu";

const TaskBottomBar = ({
	task,
	loadTasks,
	clearTasks,
	takeTaskHandler,
	setStep,
	openTaskModal,
}) => {
	const { isAppLoading } = useLoaderStore();
	const { changeTaskStatus, getMyTasks } = useTaskStore();
	const { hideModal } = useModalStore();
	const [comment, setComment] = useState({ value: "", error: false });
	const { navigate } = useRouter();

	const handleSetComment = (v) => {
		setComment((prev) => ({ ...prev, value: v, error: false }));
	};

	const handleEndClick = () => {
		setStep((prev) => prev + 1);
	};

	const navigateToTaskList = () => {
		navigate("/tasks");
		hideModal();
	};

	const cancelTaskHandler = () => {
		if (!comment.value) {
			setComment((prev) => ({ ...prev, error: true }));
			return;
		}
		setComment((prev) => ({ ...prev, error: false }));
		changeTaskStatus({
			id: task.id,
			status: ORDER_STATUS.CANCELLED,
			comment: comment.value,
			successCb: () => {
				clearTasks && clearTasks();
				getMyTasks({ page: 1, limit: 100 });
				loadTasks && loadTasks({ page: 1 });
				hideModal();
			},
		});
	};

	const approveTaskHandler = () => {
		changeTaskStatus({
			id: task.id,
			status: ORDER_STATUS.APPROVED,
			successCb: (newTaskState) => {
				openTaskModal(newTaskState);
				clearTasks && clearTasks();
				getMyTasks({ page: 1, limit: 100 });
				loadTasks && loadTasks({ page: 1 });
			},
		});
	};

	const renderActionButton = () => {
		if (!task.order_activity_id) {
			return (
				<BrandButton
					className={`${classes.button} btn-brand-xl`}
					onClick={() => takeTaskHandler(task.id)}
					disabled={isAppLoading}
				>
					{task.order?.state?.code === ORDER_STATUS.PENDING ? "Забронировать" : "В работу"}
				</BrandButton>
			);
		}

		if (task.state?.code === ORDER_STATUS.APPROVED) {
			return (
				<div className={classes.menuContainer}>
					<KebabMenu
						mainButton={
							<BrandButton
								className={`${classes.end} btn-brand-xl`}
								onClick={handleEndClick}
								disabled={isAppLoading}
							>
								Завершить
							</BrandButton>
						}
						dropdown={
							<>
								<BrandInput
									placeholder="Введите причину отмены"
									value={comment.value}
									onChange={handleSetComment}
									autoComplete="false"
									className={`${comment.error && classes.error}`}
								/>
								<BrandButton
									className={`${classes.cancel} btn-brand-non-outline w-100`}
									onClick={cancelTaskHandler}
									disabled={isAppLoading}
								>
									Отменить
								</BrandButton>
							</>
						}
					/>
				</div>
			);
		}

		if (task.state?.code === ORDER_STATUS.PENDING) {
			return (
				<div className={classes.buttonContainer}>
					{task.order?.state?.code === ORDER_STATUS.PENDING ? (
						<div className="d-flex w-100 gap-3 flex-column">
							<BrandInput
								placeholder="Введите причину отмены"
								value={comment.value}
								onChange={handleSetComment}
								autoComplete="false"
								className={`${comment.error && classes.error}`}
							/>
							<BrandButton
								className={`${classes.cancel} btn-brand-non-outline w-100`}
								onClick={cancelTaskHandler}
								disabled={isAppLoading}
							>
								Отменить бронь
							</BrandButton>
						</div>
					) : (
						<div className={classes.menuContainer}>
							<KebabMenu
								mainButton={
									<BrandButton
										className="btn-brand-xl"
										// onClick={() => takeTaskHandler(task.id)}
										onClick={approveTaskHandler}
										disabled={isAppLoading}
									>
										Начать
									</BrandButton>
								}
								dropdown={
									<>
										<BrandInput
											placeholder="Введите причину отмены"
											value={comment.value}
											onChange={handleSetComment}
											autoComplete="false"
											className={`${comment.error && classes.error}`}
										/>
										<BrandButton
											className={`${classes.cancel} btn-brand-non-outline w-100`}
											onClick={cancelTaskHandler}
											disabled={isAppLoading}
										>
											Отменить
										</BrandButton>
									</>
								}
							/>
						</div>
					)}
				</div>
			);
		}

		return (
			<div className={classes.buttonContainer}>
				<BrandButton
					className={`${classes.default} btn-brand-xl w-100`}
					disabled={isAppLoading}
					onClick={navigateToTaskList}
				>
					К заданиям
				</BrandButton>
			</div>
		);
	};

	return <>{renderActionButton()}</>;
};

export default TaskBottomBar;
