import useRouter from "@hooks/useRouter";
import useModalStore from "@store/ModalStore";
import BrandButton from "@ui/Button/BrandButton";
import PageTitle from "@ui/PageTitle/PageTitle";
import React from "react";
import classes from "../Modals.module.css";

const PaymentSuccess = ({ text }) => {
	const { navigate } = useRouter();
	const { hideModal } = useModalStore();

	const toReports = () => {
		navigate("/reports");
		hideModal();
	};

	return (
		<section className={classes.wrapper}>
			<PageTitle title="Оплата прошла" />
			<div>
				{text ? (
					<p className="text-md-medium mt-3">{text}</p>
				) : (
					<>
						<p className="text-md-medium mt-3">Баланс исполнителей обнулен.</p>
						<p className="text-md-medium mt-3">
							Исполнители без указанных реквизитов в отчет на оплату не попадают.
						</p>
					</>
				)}
			</div>
			<BrandButton className="btn-brand-xl w-100" onClick={toReports}>
				К отчетам
			</BrandButton>
		</section>
	);
};

export default PaymentSuccess;
