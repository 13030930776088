import React, { useMemo } from "react";
import classes from "./ContractorsTable.module.css";
import { USER_STATUS_COLOR } from "@const/colors";
import BrandCheckbox from "@ui/Checkbox/BrandCheckbox";
import useAuthStore from "@store/AuthStore";
import useRouter from "@hooks/useRouter";
import { SOCIALS_CODE } from "../../const/socials";

const ContractorsTable = ({ userList, selectedUsers, setSelectedUsers }) => {
	const { isOperationalDirector } = useAuthStore();
	const { navigate } = useRouter();

	const selectUser = (user) => {
		if (selectedUsers.find((e) => e.id === user.id)) {
			setSelectedUsers((prev) => prev.filter((e) => e.id !== user.id));
			return;
		}
		setSelectedUsers((prev) => [...prev, user]);
	};
	const selectAll = () => {
		if (userList?.length === selectedUsers.length) {
			setSelectedUsers([]);
			return;
		}
		setSelectedUsers(userList);
	};

	const hasInstagram = (row) =>
		row.social_network_accounts.find(
			(social) => social.social_network.code === SOCIALS_CODE.INSTAGRAM
		);

	const columns = useMemo(
		() => [
			{ id: "name", header: "Имя", accessor: (row) => `${row.name} ${row.surname}` },
			{ id: "state", header: "Статус", accessor: (row) => row.state.title },
			{ id: "email", header: "Почта", accessor: (row) => row.email },
			{
				id: SOCIALS_CODE.INSTAGRAM,
				header: "Инстаграм",
				accessor: (row) => hasInstagram(row)?.account_id,
			},
			{
				id: "balance",
				header: "Баланс",
				accessor: (row) => {
					let balance = row?.wallet?.balance;
					return balance ? `${balance} ₽` : "-";
				},
			},
			{
				id: "completed",
				header: "Принято заданий",
				accessor: (row) => row.order_activity_completed,
			},
			{ id: "total", header: "Заданий всего", accessor: (row) => row.order_activity_created },
		],
		[]
	);

	const renderCell = (cellValue, row, id) => {
		const isStatusColumn = id === "state";
		const color = isStatusColumn ? USER_STATUS_COLOR[row.state?.code] : undefined;

		return (
			<div className="text-sm-medium" style={{ color, textDecoration: "none" }}>
				{cellValue || "-"}
			</div>
		);
	};
	const isChecked = (id) => {
		return selectedUsers?.find((el) => el.id === id);
	};

	if (!userList?.length) return <h2 className="mt-4">Список исполнителей пуст</h2>;

	return (
		<div className={classes.tableContainer}>
			<table className={`tableStandart ${classes.table}`}>
				<thead className="thead">
					<tr>
						{isOperationalDirector() && (
							<th className={`th ${classes.checkbox}`}>
								<BrandCheckbox
									onChange={selectAll}
									checked={userList?.length === selectedUsers?.length}
									onClick={(e) => e.stopPropagation()}
								/>
							</th>
						)}
						{columns.map(({ id, header }) => (
							<th className="th" key={id}>
								{header}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tbody">
					{userList?.length > 0 &&
						userList.map((row, rowIndex) => (
							<tr
								key={rowIndex}
								className={`tr ${classes.tr} `}
								onClick={() => navigate(`/profile/${row.id}`)}
							>
								{isOperationalDirector() && (
									<td className={`td ${classes.checkbox}`}>
										<BrandCheckbox
											onChange={() => selectUser(row)}
											checked={isChecked(row.id)}
											onClick={(e) => e.stopPropagation()}
										/>
									</td>
								)}
								{columns.map(({ id, accessor }) => (
									<td key={id} className="td">
										{renderCell(accessor(row), row, id)}
									</td>
								))}
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};

export default ContractorsTable;
