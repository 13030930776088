export const ORDER_COLORS = {
	draft: "#101828",
	expired: "#D92D20",
	unverified: "#DC6803",
	pending: "#6938EF",
	approved: "#1570EF",
	completed: "#4CA30D",
	uncompleted: "#4CA30D",
	cancelled: "#D0D5DD",
	rejected: "#D92D20",
};

export const USER_STATUS_COLOR = {
	approved: "#4CA30D",
	draft: "#D92D20",
	blocked: "#D92D20",
};
