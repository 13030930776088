import Timer from "@components/Task/Timer/Timer";
import BrandButton from "@components/ui/Button/BrandButton";
import { ORDER_COLORS } from "@const/colors";
import { ORDER_STATUS } from "@const/order";
import { calculateTimeDifference, formatDate } from "@helpers/day";
import React from "react";
import classes from "./TaskContent.module.css";
import { TASK_TYPE } from "@const/tasks";
import { ReactComponent as Copy } from "@assets/icons/task/copy.svg";

const TaskContent = ({ task, activityLabel, statusText }) => {
	const commentInUsed = () => {
		return task.order_activity?.items?.filter((item) => item.is_used === true)[0];
	};

	const handleCopyComment = async () => {
		const comment = commentInUsed();
		if (comment) {
			try {
				await navigator.clipboard.writeText(comment.title);
				alert("Комментарий скопирован в буфер обмена!");
			} catch (err) {
				console.error("Не удалось скопировать текст:", err);
			}
		}
	};
	if (!task.order) return;

	return (
		<>
			<p className="text-sm-medium">
				<span className={classes.status} style={{ color: ORDER_COLORS[task.order.state.code] }}>
					{statusText}
				</span>
				<span className={classes.number}>• #{task.order.number}</span>
			</p>
			<h2 className={` ${classes.title}`}>{activityLabel}</h2>
			{task.order.url ? (
				<BrandButton
					to={task.order.url}
					target="_blank"
					className={`btn-brand-non-outline ${classes.href} `}
				>
					Ссылка на пост
				</BrandButton>
			) : (
				<div className={classes.emptyUrl}>
					<p className="text-sm-medium">Ссылка скоро появится</p>
				</div>
			)}
			{task.started_on && (
				<div>
					<div>
						<p className={`${classes.label} text-sm-medium`}>Дата старта</p>
						<p className="text-sm-medium">
							{formatDate({ dateString: task.started_on, withTime: true, month: true })}
						</p>
					</div>
				</div>
			)}
			{task.finished_on && task.state?.code === ORDER_STATUS.APPROVED && (
				<div>
					<p className={`${classes.label} text-sm-medium`}>Срок выполнения</p>
					<Timer initialTime={calculateTimeDifference(task.finished_on)} />
				</div>
			)}
			{task.state?.code === ORDER_STATUS.PENDING && (
				<div>
					<p className={`${classes.label} text-sm-medium`}>Срок выполнения</p>
					<p className="text-sm-medium">1 час</p>
				</div>
			)}
			<div>
				<p className={`${classes.label} text-sm-medium`}>Описание</p>
				<p className="text-md-medium">{task.order.comment}</p>
			</div>

			{task.completed_screenshot && (
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}${task.completed_screenshot}`}
					alt="screenshot"
					className={classes.screenshot}
				/>
			)}
			{task.verified_comment && (
				<div>
					<p className={`${classes.label} text-sm-medium`}>Причина отклонения</p>
					<p className="text-md-medium">{task.verified_comment}</p>
				</div>
			)}
			{task.cancellation_comment && (
				<div>
					<p className={`${classes.label} text-sm-medium`}>Причина отмены</p>
					<p className="text-md-medium">{task.cancellation_comment}</p>
				</div>
			)}
			{task.order_activity?.social_network_activity?.activity.code === TASK_TYPE.COMMENT && (
				<div>
					<p className={`${classes.label} text-sm-medium`}>Комментарий</p>
					<div className="text-md-medium">
						{task.order_activity?.items?.length > 0 ? (
							<div className={classes.copyWrapper}>
								<p>{commentInUsed()?.title}</p>
								<Copy onClick={handleCopyComment} className={classes.copyIcon} />
							</div>
						) : (
							"Ознакомьтесь с контентом и оставьте комментарий по теме поста"
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default TaskContent;
