import ReportList from "@components/ReportBlock/ReportList/ReportList";
import ShowMore from "@components/ShowMore/ShowMore";
import useReportStore from "@store/ReportStore";
import React, { useEffect, useState } from "react";
import InfoBlock from "@components/ui/InfoBlock/InfoBlock";
import classes from "./ReportsPage.module.css";
import useAuthStore from "@store/AuthStore";
import PageTitle from "@ui/PageTitle/PageTitle";

const ReportsPage = () => {
	const { getWithdrawalSessionList, withdrawalSessionList, setWithdrawalSessionList } =
		useReportStore();
	const { isOperationalDirector } = useAuthStore();

	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const limit = 10;

	const setMoreWithdrawal = (newWithdrawal) => {
		setTotalCount(newWithdrawal.total);

		setWithdrawalSessionList([...withdrawalSessionList, ...newWithdrawal.items]);
	};

	useEffect(() => {
		if (!isOperationalDirector()) return;
		getWithdrawalSessionList({ page: currentPage, limit, successCb: setMoreWithdrawal });
	}, [currentPage]);

	const clearParams = () => {
		setCurrentPage(1);
		setTotalCount(0);
		setWithdrawalSessionList([]);
	};

	useEffect(() => {
		return () => clearParams();
	}, []);

	return (
		<div className="pb-5">
			<PageTitle title="Отчеты" />
			{isOperationalDirector() && (
				<InfoBlock className={classes.infoBlock}>
					<p className={`text-sm-medium ${classes.title}`}>История выплат</p>
					<ReportList data={withdrawalSessionList} />
					{totalCount > withdrawalSessionList.length && (
						<ShowMore
							data={withdrawalSessionList}
							setCurrentPage={setCurrentPage}
							totalCount={totalCount}
							className={classes.showMore}
						/>
					)}
				</InfoBlock>
			)}
		</div>
	);
};

export default ReportsPage;
