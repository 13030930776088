import InfiniteScrollLoader from "@components/InfiniteScrollLoader/InfiniteScrollLoader";
import { ORDER_STATUS } from "@const/order";
import useTaskLoader from "@hooks/useTaskLoader";
import useOrderStore from "@store/OrderStore";
import useTaskStore from "@store/TaskStore";
import React, { useEffect, useState } from "react";
import CancelTaskComment from "./CheckBlock/CancelTaskComment/CancelTaskComment";
import CheckBlock from "./CheckBlock/CheckBlock";
import OrderTaskCount from "./OrderTaskCount/OrderTaskCount";
import OrderTaskListTable from "./OrderTaskListTable/OrderTaskListTable";
import classes from "./TaskCheckContainer.module.css";
import useModalStore from "@/store/ModalStore";

const TaskCheckContainer = ({ orderId }) => {
	const { getOrderTaskCount, getOrderTaskList, orderTaskCount, getOrderInfo } = useOrderStore();
	const { taskListForCheck, setTaskListForCheck } = useTaskStore();
	const { showModal } = useModalStore();

	const [showCancelledBlock, setShowCancelledBlock] = useState(false);
	const [initialStatus, setInitialStatus] = useState("");

	const getInitStatus = () => {
		const statuses = [
			ORDER_STATUS.UNVERIFIED,
			ORDER_STATUS.COMPLETED,
			ORDER_STATUS.REJECTED,
			ORDER_STATUS.APPROVED,
			ORDER_STATUS.PENDING,
		];

		for (const status of statuses) {
			const found = orderTaskCount.find((task) => task.code === status && task.count > 0);
			if (found) {
				setInitialStatus(found.id);
				return;
			}
		}
	};

	const {
		taskStatus,
		fetching,
		totalCount,
		loadedTasks,
		handleTabSelect,
		loadTasks,
		setFetching,
		clearTasks,
		currentPage,
	} = useTaskLoader({
		initialTaskStatus: initialStatus,
		fetchTasks: getOrderTaskList,
		itemsPerPage: 5,
		filterField: "state_id",
		id: orderId,
	});

	const unverifiedTaskList = loadedTasks.filter(
		(task) => task.state.code === ORDER_STATUS.UNVERIFIED
	);

	const checkTaskHandler = (task) => {
		if (taskListForCheck.find((el) => el.id === task.id)) {
			setTaskListForCheck(taskListForCheck.filter((el) => el.id !== task.id));
			return;
		}
		setTaskListForCheck([...taskListForCheck, task]);
	};

	const removeCheckedTask = () => {
		clearTasks();
		setTaskListForCheck([]);
		getOrderTaskCount(orderId);
		loadTasks({ page: 1, id: orderId });
		getOrderInfo(orderId);
	};

	useEffect(() => {
		if (orderTaskCount?.length > 0) {
			getInitStatus();
		}
	}, [orderTaskCount, orderId]);

	useEffect(() => {
		if (!orderId) return;
		getOrderTaskCount(orderId);
		setInitialStatus("");
	}, [orderId]);

	const showCancelBlockInModal = () => {
		showModal(
			<CancelTaskComment
				setShowCancelledBlock={setShowCancelledBlock}
				removeCheckedTask={removeCheckedTask}
			/>,
			"cancelBlock"
		);
	};
	return (
		<>
			{orderTaskCount?.length > 0 && loadedTasks?.length > 0 && (
				<OrderTaskCount
					orderTaskCount={orderTaskCount}
					taskStatus={taskStatus}
					setTaskStatus={handleTabSelect}
					setTaskListForCheck={setTaskListForCheck}
				/>
			)}
			{loadedTasks?.length > 0 && (
				<div className="d-flex gap-5">
					<OrderTaskListTable
						orderTaskList={loadedTasks}
						taskListForCheck={taskListForCheck}
						checkTaskHandler={checkTaskHandler}
						setTaskListForCheck={setTaskListForCheck}
						unverifiedTaskList={unverifiedTaskList}
						removeCheckedTask={removeCheckedTask}
					/>
					<InfiniteScrollLoader
						fetching={fetching}
						setFetching={setFetching}
						loadedTasks={loadedTasks}
						totalCount={totalCount}
						currentPage={currentPage}
					/>

					<div className={classes.checkBlockWrapper}>
						<CheckBlock
							taskListForCheck={taskListForCheck}
							orderTaskList={loadedTasks}
							unverifiedTaskList={unverifiedTaskList}
							showCancelledBlock={showCancelledBlock}
							setShowCancelledBlock={setShowCancelledBlock}
							removeCheckedTask={removeCheckedTask}
							showCancelBlockInModal={showCancelBlockInModal}
						/>

						{showCancelledBlock && (
							<CancelTaskComment
								setShowCancelledBlock={setShowCancelledBlock}
								removeCheckedTask={removeCheckedTask}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default TaskCheckContainer;
