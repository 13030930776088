import React, { useState } from "react";
import PageTitle from "@ui/PageTitle/PageTitle";
import BrandInput from "@ui/Input/BrandInput";
import BrandButton from "@ui/Button/BrandButton";
import useUserStore from "@store/UserStore";
import useModalStore from "@store/ModalStore";

const CreateCustomer = ({ handleSelectCustomer }) => {
	const { createCustomer } = useUserStore();
	const [customerNick, setCustomerNick] = useState("");
	const { hideModal } = useModalStore();

	const validateInput = (input) => {
		const regex = /^[a-zA-Z0-9._-]{0,30}$/;
		return regex.test(input);
	};

	const handleInputChange = (v) => {
		if (validateInput(v)) {
			setCustomerNick(v);
		}
	};

	const successCreateCustomer = (data) => {
		handleSelectCustomer(data.item);
		hideModal();
		setCustomerNick("");
	};

	const handleButtonClick = () => {
		createCustomer({
			customerNick,
			successCb: successCreateCustomer,
		});
	};

	return (
		<div className="d-flex flex-column gap-5" style={{ width: "350px" }}>
			<PageTitle title="Новый заказчик" />
			<BrandInput
				placeholder="Никнейм"
				label="Никнейм в инстаграм"
				value={customerNick}
				onChange={handleInputChange}
			/>
			<BrandButton className="btn-brand-xl w-100" onClick={handleButtonClick}>
				Добавить
			</BrandButton>
		</div>
	);
};

export default CreateCustomer;
