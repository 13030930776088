import React from "react";
import { ReactComponent as PurpleLogo } from "@assets/icons/logo/purpleLogo.svg";
import { ReactComponent as TurquoiseLogo } from "@assets/icons/logo/turquoiseLogo.svg";
import { ReactComponent as RoseLogo } from "@assets/icons/logo/roseLogo.svg";
import { ReactComponent as OrangeLogo } from "@assets/icons/logo/orangeLogo.svg";
import { ReactComponent as GrayLogo } from "@assets/icons/logo/grayLogo.svg";
import { THEMES_TYPE } from "@const/themes";

const Logo = () => {
	const theme = document.documentElement.getAttribute("data-theme");

	const renderLogo = () => {
		switch (theme) {
			case THEMES_TYPE.TURQUOISE:
				return <TurquoiseLogo />;
			case THEMES_TYPE.ROSE:
				return <RoseLogo />;
			case THEMES_TYPE.ORANGE:
				return <OrangeLogo />;
			case THEMES_TYPE.GRAY:
				return <GrayLogo />;
			case THEMES_TYPE.PURPLE:
			default:
				return <PurpleLogo />;
		}
	};

	return renderLogo();
};

export default Logo;
