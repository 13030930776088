import { useEffect } from "react";
import useRouter from "../../hooks/useRouter";
const UseHistory = () => {
	const { location } = useRouter();

	const saveNavigationHistory = (path) => {
		const history = JSON.parse(localStorage.getItem("navigationHistory")) || [];
		if (history[history.length - 1] !== path) {
			history.push(path);
			localStorage.setItem("navigationHistory", JSON.stringify(history));
		}
	};
	const clearNavigationHistory = () => {
		localStorage.removeItem("navigationHistory");
	};

	useEffect(() => {
		saveNavigationHistory(location.pathname);

		window.addEventListener("beforeunload", clearNavigationHistory);

		return () => {
			window.removeEventListener("beforeunload", clearNavigationHistory);
		};
	}, [location.pathname]);
};

export default UseHistory;
