import BrandButton from "@components/ui/Button/BrandButton";
import useRouter from "@hooks/useRouter";
import useProfileStore from "@store/ProfileStore";
import React, { useEffect } from "react";
import classes from "./Balance.module.css";
import { formatBalance } from "@helpers";

const Balance = () => {
	const { profileInfo, getProfileInfo } = useProfileStore();
	const { navigate } = useRouter();

	useEffect(() => {
		getProfileInfo();
	}, []);

	return (
		<BrandButton className={`${classes.balance} btn-brand-xl`} onClick={() => navigate("/profile")}>
			{formatBalance(profileInfo?.wallet?.balance)}
		</BrandButton>
	);
};

export default Balance;
