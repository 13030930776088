import { ReactComponent as Plus } from "@assets/icons/button/plus.svg";
import InviteContractor from "@components/Modals/InviteContractor/InviteContractor";
import BrandButton from "@components/ui/Button/BrandButton";
import useHeaderStore from "@store/HeaderStore";
import useModalStore from "@store/ModalStore";
import React from "react";

const ContractorPageHeader = () => {
	const { isHeaderFixed } = useHeaderStore();
	const { showModal } = useModalStore();

	return (
		<>
			{isHeaderFixed && <p className="text-md-medium">Исполнители</p>}

			<BrandButton
				onClick={() => showModal(<InviteContractor />)}
				className="btn-brand-xl mobile-hide ms-auto"
				icon={<Plus />}
			>
				Добавить исполнителя
			</BrandButton>
		</>
	);
};

export default ContractorPageHeader;
