import React, { useEffect, useState } from "react";
import useAuthStore from "@store/AuthStore";
import classes from "./PartingWords.module.css";
import useDictionaryStore from "@store/DictionaryStore";
import { MESSAGE_TYPE } from "@const/dictionary";

const PartingWords = () => {
	const { profileData } = useAuthStore();
	const [greetingMessage, setGreetingMessage] = useState("");
	const { getMessagesType, messagesType, getTypeCode, getRandomMessage } = useDictionaryStore();

	useEffect(() => {
		if (messagesType.length) return;
		getMessagesType();
	}, [messagesType]);

	useEffect(() => {
		if (!messagesType.length) return;
		const type_code = getTypeCode(MESSAGE_TYPE.GREETING);

		getRandomMessage({ type_code, successCb: setGreetingMessage });
	}, [messagesType]);

	const greetingMessageSlice = () => {
		const msg = greetingMessage?.message;
		return msg?.length > 80 ? msg.slice(0, 821) + "..." : msg;
	};

	return (
		<div className={classes.wrapper}>
			<h1 className={classes.heading}>
				Привет, {profileData?.name}!<br /> {greetingMessageSlice()}
			</h1>
		</div>
	);
};

export default PartingWords;
