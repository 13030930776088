import { create } from "zustand";
import useRequestStore from "./RequestStore";
import useAuthStore from "./AuthStore";
import useModuleStore from "./ModuleStore";
import useProfileStore from "./ProfileStore";

const useUserStore = create((set, get) => ({
	userList: [],

	setUserList: (userList) => set({ userList }),
	createCustomer: ({ customerNick, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("customers:create", {
			requestData: { name: customerNick },
			successCb: (responseData) => {
				console.log("Заказчик успешно создан:", responseData);
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка создания заказчика:", error);
			},
		});
	},
	inviteContractor: ({ contractorData, setRequestError, hideModal }) => {
		const { name, surname, email } = contractorData;
		const handleRequest = useRequestStore.getState().handleRequest;
		const changeRoles = useAuthStore.getState().changeRoles;
		handleRequest("users:create", {
			requestData: { name, surname, email },
			successCb: (responseData) => {
				const userId = responseData.item.id;
				changeRoles({ user_id: userId, roles: ["contractor"], successCb: () => hideModal() });
				console.error("Приглашение успешно отправлено", responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка отправки приглашения:", error);
				setRequestError(error.title);
			},
		});
	},
	getUserList: ({ page, limit, filters, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:list", {
			requestData: { page, limit, filters },
			successCb: (responseData) => {
				set({ userList: responseData.items });
				console.log("Список пользователей", responseData);
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка отправки приглашения:", error);
			},
		});
	},
	getUserModuleStateId: (state_code) => {
		const { getUserModule } = useModuleStore.getState();
		let blockState = getUserModule()?.states.filter((module) => module.code === state_code)[0];
		return blockState.id;
	},
	changeUserStatus: ({ entity_id, state_code, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:change_state", {
			requestData: { entity_id, state_id: get().getUserModuleStateId(state_code) },
			successCb: (responseData) => {
				const getOtherProfileInfo = useProfileStore.getState().getOtherProfileInfo;
				getOtherProfileInfo({ entity_id: entity_id });
				console.log("Статус успешно изменен", responseData);
				successCb && successCb();
			},
			errorCb: (error) => {
				console.error("Ошибка изменения статуса", error);
			},
		});
	},
	deleteUser: ({ entity_id, successCb }) => {
		console.log("delete", entity_id);
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:delete", {
			requestData: { entity_id },
			successCb: (responseData) => {
				console.log("Пользователь успешно удален", responseData);
				successCb && successCb();
			},
			errorCb: (error) => {
				console.error("Ошибка удаления пользователя", error);
			},
		});
	},
}));

export default useUserStore;
