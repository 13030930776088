import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import BrandDropdown from "@ui/Dropdown/BrandDropdown";
import BrandLink from "@ui/Link/BrandLink";
import { ReactComponent as ChangeRole } from "@assets/icons/sidebar/changeRole.svg";
import { ReactComponent as ChevronDown } from "@assets/icons/button/chevronDown.svg";
import useAuthStore from "@store/AuthStore";
import { HEADER_MENU } from "@const/headerConfig";
import { USER_ROLE } from "@const/roles";
import useRouter from "@hooks/useRouter";
import classes from "./MobileMenuContent.module.css";

const MobileNav = ({ closeMenu }) => {
	const { userRole, userRoles, setActiveRole } = useAuthStore();
	const { navigate } = useRouter();
	const [isOpen, setIsOpen] = useState(false);
	const [isMenuVisible, setIsMenuVisible] = useState(true);

	const MENU = {
		[USER_ROLE.MANAGER]: HEADER_MENU.MANAGER_HEADER_LINKS,
		[USER_ROLE.OPERATIONAL_DIRECTOR]: HEADER_MENU.MANAGER_HEADER_LINKS,
		[USER_ROLE.CONTRACTOR]: HEADER_MENU.CONTRACTOR_HEADER_LINKS,
		[USER_ROLE.ADMINISTRATOR]: HEADER_MENU.MANAGER_HEADER_LINKS,
	};

	const toggleDropdown = () => {
		setIsOpen((prev) => !prev);
	};

	const handleRoleChange = (role) => {
		setIsMenuVisible(false);

		setActiveRole({
			role: role.role.code,
			successCb: () => setIsMenuVisible(true),
			navigate,
		});
	};

	return (
		<ul className={`${classes.menu} ${isMenuVisible ? classes.menuVisible : classes.menuHidden}`}>
			{MENU[userRole].map((link) => (
				<li key={link.to} className={classes.menuItem}>
					<BrandLink
						to={link.to}
						title={link.title}
						onClick={closeMenu}
						className={`text-md-semibold ${classes.link} ${
							window.location.pathname === link.to ? classes.activeLink : ""
						}`}
					>
						{link.img}
						<p className={window.location.pathname === link.to ? classes.activeTitle : ""}>
							{link.title}
						</p>
					</BrandLink>
				</li>
			))}
			{userRoles?.length > 1 && (
				<li className={classes.menuItem}>
					<BrandDropdown
						showDropdown={isOpen}
						triggerComponent={
							<div
								className={`text-md-semibold ${classes.link} ${classes.dropdownTrigger}`}
								onClick={toggleDropdown}
							>
								<div className={classes.roleWrapper}>
									<ChangeRole />
									<p className="text-md-semibold">Сменить роль</p>
								</div>
								<ChevronDown className={`${classes.chevron} ${isOpen ? classes.rotate : ""}`} />
							</div>
						}
						dropdownMenu={
							<ul>
								{userRoles?.length > 0 &&
									userRoles.map((role) => {
										const isActive = userRole === role.role.code;
										const handleClick = () => handleRoleChange(role);

										return (
											<li
												key={role.id}
												onClick={handleClick}
												className={`${isActive ? classes.activeRole : ""} ${classes.role}`}
											>
												<Dropdown.Item className={isActive ? classes.activeRole : ""}>
													{role.role.title}
												</Dropdown.Item>
											</li>
										);
									})}
							</ul>
						}
						dropdownMenuClassName={classes.dropdownMenuMobile}
					/>
				</li>
			)}
		</ul>
	);
};

export default MobileNav;
