import React, { useEffect } from "react";
import PageTitle from "@ui/PageTitle/PageTitle";
import useNotificationStore from "@store/NotificationStore";

const Notifications = () => {
	const { getNotificationList, notificationList } = useNotificationStore();

	useEffect(() => {
		getNotificationList({ page: 1, limit: 100 });
	}, []);

	return (
		<div style={{ minHeight: "2000px" }}>
			<PageTitle title="Уведомления" />
			{JSON.stringify(notificationList.slice(0, 2))}
		</div>
	);
};

export default Notifications;
