import ProfileMainInfo from "@components/ProfileBlock/ProfileMainInfo/ProfileMainInfo";
import useRouter from "@hooks/useRouter";
import ProfileTopInfo from "@components/ProfileBlock/ProfileTopInfo/ProfileTopInfo";
import useProfileStore from "@store/ProfileStore";
import React, { useEffect } from "react";
import ProfilePageMobileBottomBar from "./mobileBottomBar/ProfilePageMobileBottomBar";

const ProfilePage = () => {
	const { profileInfo, getProfileInfo, getOtherProfileInfo } = useProfileStore();
	const { params } = useRouter();
	const { id } = params;

	useEffect(() => {
		if (id) {
			console.log("getOtherProfile");
			getOtherProfileInfo({ entity_id: id });
			return;
		}
		getProfileInfo();
	}, [id]);

	if (!profileInfo) return;

	return (
		<>
			<ProfileTopInfo />
			<ProfileMainInfo profileInfo={profileInfo} id={id} />
			<ProfilePageMobileBottomBar id={id} />
		</>
	);
};

export default ProfilePage;
