import { create } from "zustand";
import useRequestStore from "./RequestStore";

const useNotificationStore = create((set, get) => ({
	notificationList: [],
	setNotificationList: (notificationList) => set({ notificationList }),
	getNotificationList: ({ page, limit, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;

		handleRequest("notification_messages:list", {
			requestData: { page, limit },
			successCb: (responseData) => {
				console.log("notificationList", responseData);

				get().setNotificationList(responseData.items);
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка получения списка уведомлений:", error);
			},
		});
	},
}));

export default useNotificationStore;
