import React from "react";
import BrandButton from "@components/ui/Button/BrandButton";
import useUserStore from "@store/UserStore";
import { USER_STATUS } from "@const/user";
import useProfileStore from "@store/ProfileStore";
import FineContractor from "@components/Modals/FineContractor/FineContractor";
import useModalStore from "@store/ModalStore";
import OncePayment from "@components/Modals/PaymentSuccess/OncePayment";

export const PayButton = ({ className }) => {
	const { profileInfo } = useProfileStore();
	const { showModal } = useModalStore();
	return (
		<BrandButton
			className={`${className || ""} btn-brand-xl`}
			disabled={!profileInfo?.bank_account || !profileInfo?.wallet?.balance}
			onClick={() => showModal(<OncePayment id={profileInfo?.id} />)}
		>
			Заплатить
		</BrandButton>
	);
};
export const BlockButton = ({ className }) => {
	const { profileInfo } = useProfileStore();
	const { changeUserStatus } = useUserStore();

	return (
		<BrandButton
			className={`${className || ""} btn-brand-outline`}
			onClick={() =>
				changeUserStatus({
					entity_id: profileInfo?.id,
					state_code:
						profileInfo?.state?.code === USER_STATUS.BLOCKED
							? USER_STATUS.APPROVED
							: USER_STATUS.BLOCKED,
				})
			}
		>
			{profileInfo?.state?.code === USER_STATUS.BLOCKED ? "Разблокировать" : "Заблокировать"}
		</BrandButton>
	);
};
export const FineButton = ({ className }) => {
	const { profileInfo } = useProfileStore();
	const { showModal } = useModalStore();
	return (
		<BrandButton
			className={`${className || ""} btn-brand-outline`}
			disabled={!profileInfo?.bank_account || !profileInfo?.wallet?.balance}
			onClick={() => showModal(<FineContractor id={profileInfo?.id} />)}
		>
			Оштрафовать
		</BrandButton>
	);
};
