import React from "react";

const PageTitle = ({ title, className, style }) => {
	return (
		<h1 className={`${className || ""} mb-5`} style={style}>
			{title}
		</h1>
	);
};

export default PageTitle;
