import BrandButton from "@ui/Button/BrandButton";
import PageTitle from "@ui/PageTitle/PageTitle";
import React from "react";
import classes from "../Modals.module.css";
import useProfileStore from "@store/ProfileStore";
import useUserStore from "@store/UserStore";
import useAuthStore from "@store/AuthStore";

const DeleteUser = () => {
	const { profileInfo } = useProfileStore();
	const { deleteUser } = useUserStore();
	const { logout } = useAuthStore();
	return (
		<section className={classes.wrapper}>
			<PageTitle title="Удалить профиль?" />
			<p className="text-sm-medium">
				После подтверждения ваш профиль будет удален из системы вместе со всеми данными
			</p>

			<BrandButton
				className="btn-brand-xl w-100"
				onClick={() => deleteUser({ entity_id: profileInfo?.id })}
			>
				Подтвердить
			</BrandButton>
		</section>
	);
};

export default DeleteUser;
