import React, { useState, useEffect } from "react";
import { formatTime } from "@helpers/day";

const Timer = ({ initialTime }) => {
	const parseTimeToSeconds = (timeString) => {
		if (!timeString) return null;
		const [hours, minutes, seconds] = timeString
			.match(/(\d+ч)?(\d+м)?(\d+с)?/)
			.slice(1)
			.map((value) => parseInt(value) || 0);

		return hours * 3600 + minutes * 60 + seconds;
	};

	const initialSeconds = parseTimeToSeconds(initialTime);

	const [secondsLeft, setSecondsLeft] = useState(
		initialSeconds !== null ? initialSeconds + 60 : null
	);

	useEffect(() => {
		if (secondsLeft === null) return;

		const timerId = setInterval(() => {
			setSecondsLeft((prevSeconds) => {
				if (prevSeconds <= 1) {
					clearInterval(timerId);
					return 0;
				}
				return prevSeconds - 1;
			});
		}, 1000);

		return () => clearInterval(timerId);
	}, [secondsLeft]);

	if (secondsLeft === null) {
		return <p className="text-sm-medium">Время вышло</p>;
	}

	return <p className="text-sm-medium">{formatTime(secondsLeft)}</p>;
};

export default Timer;
