import React from "react";
import { ReactComponent as Graduation } from "@assets/icons/button/graduation.svg";
import BrandButton from "@components/ui/Button/BrandButton";
import BackButton from "@ui/BackButton/BackButton";
import useHeaderStore from "@store/HeaderStore";
import useOrderStore from "@store/OrderStore";
import useRouter from "@hooks/useRouter";
import classes from "./CreateOrderPageHeader.module.css";
const CreateOrderPageHeader = () => {
	const { location, navigate, params } = useRouter();
	const { id } = params;
	const { isHeaderFixed } = useHeaderStore();
	const { createOrderHandler, calculateSum, activeTasks } = useOrderStore();

	const isEdit = location.pathname.includes("edit");
	const orderSum = calculateSum(activeTasks);

	const titleElement = isHeaderFixed && (
		<p className="text-md-medium">{isEdit ? "Редактирование заказа" : "Новый заказ"}</p>
	);

	const sumElement = isHeaderFixed && (
		<p className="text-md-medium" style={{ color: "#667085" }}>
			{orderSum} ₽
		</p>
	);

	return (
		<>
			<div>
				<div className="d-flex gap-3 align-items-center mobile-hide">
					<BackButton />
					<div>
						{titleElement}
						{sumElement}
					</div>
				</div>
				<div className="desktop-hide">
					{isHeaderFixed && (
						<div>
							{titleElement}
							{sumElement}
						</div>
					)}
				</div>
			</div>
			<div className="d-flex gap-2">
				{!isEdit && (
					<BrandButton
						className="btn-brand-outline mobile-hide"
						icon={<Graduation className={classes.graduation} />}
					>
						Как создавать заказы
					</BrandButton>
				)}
				<BrandButton
					className="btn-brand-xl mobile-hide"
					onClick={(e) => createOrderHandler(e, navigate, isEdit, id)}
				>
					{isEdit ? "Редактировать заказ" : "Создать заказ"}
				</BrandButton>
			</div>
		</>
	);
};

export default CreateOrderPageHeader;
