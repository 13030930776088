import { create } from "zustand";
import useRequestStore from "./RequestStore";

const useReportStore = create((set, get) => ({
	withdrawalSessionList: [],
	setWithdrawalSessionList: (withrawalList) => set({ withdrawalSessionList: withrawalList }),
	getWithdrawalSessionList: ({ page, limit, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;

		handleRequest("user_wallet_withdrawal_sessions:list", {
			requestData: { page, limit },
			successCb: (responseData) => {
				get().setWithdrawalSessionList(responseData.items);
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка получения списка сессий вывода средств:", error);
			},
		});
	},
	downloadWithdrawalReport: ({ id }) => {
		const handleRequest = useRequestStore.getState().handleRequest;

		handleRequest("user_wallet_withdrawal_sessions:download", {
			requestData: { entity_id: id },
			successCb: (data) => {
				const link = document.getElementById("dowload-link");
				const blob = new Blob([data.content], {
					type: "octet/stream",
				});
				const url = window.URL.createObjectURL(blob);

				link.href = url;

				link.download = data.name;

				link.click();

				window.URL.revokeObjectURL(url);
			},
			errorCb: (error) => {
				console.error("Ошибка скачивания отчета:", error);
			},
		});
	},
}));

export default useReportStore;
