import { create } from "zustand";

const useToastStore = create((set, get) => ({
	isShowToast: false,
	toastMessage: null,
	delay: 5000,
	position: "top-center",
	setToastMessage: (content) => {
		set({ toastMessage: content });
	},
	showToast: (content) => {
		set({
			isShowToast: true,
		});
		get().setToastMessage(content);
	},
	hideToast: () => {
		set({
			isShowToast: false,
		});
		get().setToastMessage(null);
	},
}));

export default useToastStore;
