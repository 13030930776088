import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import InfiniteScrollLoader from "@components/InfiniteScrollLoader/InfiniteScrollLoader";
import TaskList from "@components/Task/TaskList/TaskList";
import { ORDER_STATUS } from "@const/order";
import useTaskStore from "@store/TaskStore";
import useTaskLoader from "@hooks/useTaskLoader";
import classes from "./TaskHistoryPage.module.css";
import PageTitle from "@ui/PageTitle/PageTitle";
const TasksHistoryPage = () => {
	const { getTaskHistory } = useTaskStore();
	const {
		taskStatus,
		fetching,
		totalCount,
		loadedTasks,
		handleTabSelect,
		loadTasks,
		setFetching,
		clearTasks,
		currentPage,
	} = useTaskLoader({
		initialTaskStatus: "all",
		fetchTasks: getTaskHistory,
		itemsPerPage: 10,
		filterField: "state_code",
	});

	const tabs = [
		{ eventKey: "all", title: "Все" },
		{ eventKey: ORDER_STATUS.EXPIRED, title: "Просроченные" },
		{ eventKey: ORDER_STATUS.UNVERIFIED, title: "На проверке" },
		{ eventKey: ORDER_STATUS.COMPLETED, title: "Принятые" },
		{ eventKey: ORDER_STATUS.REJECTED, title: "Отклоненные" },
		{ eventKey: ORDER_STATUS.CANCELLED, title: "Отмененные" },
	];

	return (
		<div>
			<PageTitle  title="История заданий" />
			<InfiniteScrollLoader
				fetching={fetching}
				setFetching={setFetching}
				loadedTasks={loadedTasks}
				totalCount={totalCount}
				currentPage={currentPage}
			/>
			<Tabs
				defaultActiveKey={"all"}
				id="history-task-tab"
				className={classes.tabWrapper}
				onSelect={handleTabSelect}
			>
				{tabs.map((tab) => (
					<Tab
						key={tab.eventKey}
						eventKey={tab.eventKey}
						title={tab.title}
						className={`${classes.tab}`}
						disabled={taskStatus === tab.eventKey}
					>
						<TaskList tasks={loadedTasks} loadTasks={loadTasks} clearTasks={clearTasks} />
					</Tab>
				))}
			</Tabs>
		</div>
	);
};

export default TasksHistoryPage;
