import AppLoader from "@components/ui/Loader/AppLoader/AppLoader";
import React, { useState } from "react";
import TaskSuccess from "../TaskSuccess/TaskSuccess";
import UploadImage from "../UploadImage/UploadImage";
import TaskBottomBar from "./TaskBottomBar/TaskBottomBar";
import TaskContent from "./TaskContent/TaskContent";
import classes from "./TaskFullScreen.module.css";

const TaskFullScreen = (props) => {
	const { task, loadTasks, clearTasks, activityLabel, takeTaskHandler, statusText, openTaskModal } =
		props;

	const [step, setStep] = useState(1);

	return (
		<div className={classes.dialogContent}>
			<AppLoader />

			{step === 1 && (
				<>
					<TaskContent task={task} activityLabel={activityLabel} statusText={statusText} />

					<TaskBottomBar
						task={task}
						takeTaskHandler={takeTaskHandler}
						setStep={setStep}
						loadTasks={loadTasks}
						clearTasks={clearTasks}
						openTaskModal={openTaskModal}
					/>
				</>
			)}

			{step === 2 && (
				<UploadImage
					task={task}
					loadTasks={loadTasks}
					clearTasks={clearTasks}
					setStep={setStep}
					step={step}
				/>
			)}

			{step === 3 && <TaskSuccess task={task} setStep={setStep} />}
		</div>
	);
};

export default TaskFullScreen;
