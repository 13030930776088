import useTransactionStore from "@/store/TransactionStore";
import useModalStore from "@store/ModalStore";
import useProfileStore from "@store/ProfileStore";
import BrandButton from "@ui/Button/BrandButton";
import PageTitle from "@ui/PageTitle/PageTitle";
import React from "react";
import classes from "../Modals.module.css";
import PaymentSuccess from "./PaymentSuccess";

const OncePayment = ({ id }) => {
	const { showModal, hideModal } = useModalStore();
	const { balanceDebiting } = useTransactionStore();
	const { getOtherProfileInfo } = useProfileStore();

	const paymentSuccess = () => {
		showModal(<PaymentSuccess text="Баланс исполнителя обнулен." />);
		getOtherProfileInfo({ entity_id: id });
	};

	const approvePayment = () => {
		balanceDebiting({ user_ids: [id], successCb: paymentSuccess });
	};

	return (
		<section className={classes.wrapper}>
			<PageTitle title="Заплатить исполнителю?" />
			<BrandButton className="btn-brand-xl w-100 mt-5" onClick={approvePayment}>
				Подтвердить оплату
			</BrandButton>
			<BrandButton
				className={`${classes.cancellPay} btn-brand-non-outline w-100`}
				onClick={hideModal}
			>
				Отменить
			</BrandButton>
		</section>
	);
};

export default OncePayment;
