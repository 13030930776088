import React from "react";
import Toast from "react-bootstrap/Toast";
import useToastStore from "@store/ToastStore";
import { ToastContainer } from "react-bootstrap";
import classes from "./BrandToast.module.css";

const BrandToast = () => {
	const { isShowToast, toastMessage, hideToast, delay, position } = useToastStore();
	return (
		<ToastContainer className={classes.container} position={position} style={{ zIndex: 999 }}>
			<Toast
				onClose={hideToast}
				show={isShowToast}
				className={classes.toast}
				delay={delay}
				autohide
			>
				<Toast.Body>{toastMessage}</Toast.Body>
			</Toast>
		</ToastContainer>
	);
};

export default BrandToast;
