import { convertISO8601Duration, formatDate } from "@helpers/day";
import useRouter from "@hooks/useRouter";
import useModalStore from "@store/ModalStore";
import useModuleStore from "@store/ModuleStore";
import useOrderStore from "@store/OrderStore";
import BrandInput from "@ui/Input/BrandInput";
import BrandSearchInput from "@ui/SearchInput/BrandSearchInput";
import BrandSwitch from "@ui/Switch/BrandSwitch";
import BrandTextArea from "@ui/TextArea/BrandTextArea";
import React, { useEffect, useRef } from "react";
import CreateCustomer from "../../Modals/CreateCustomer/CreateCustomer";
import classes from "./CreateOrderForm.module.css";
import OrderSum from "./OrderSum/OrderSum";
import TaskBlock from "./Tasks/TaskBlock/TaskBlock";
import TaskList from "./Tasks/TaskList/TaskList";

export const CreateOrderForm = ({ isEdit }) => {
	const {
		getCustomerList,
		clearCustomerList,
		customerList,
		socialNetworkList,
		getSocialNetworkList,
		getInstagramInfo,
		orderData,
		activeTasks,
		setActiveTasks,
		isFieldValid,
		selectedSocial,
		setSelectedSocial,
		updateOrderData,
		getOrderInfo,
		currentOrder,
		resetOrderForm,
		getTaskTitle,
		getActivityComments,
	} = useOrderStore();
	const { moduleList, getSocialNetworkModule, getActiveStateForModule } = useModuleStore();
	const { showModal, hideModal } = useModalStore();
	const { params } = useRouter();
	const { id } = params;
	const taskRefs = useRef([]);

	useEffect(() => {
		const currentRef = taskRefs.current[taskRefs.current.length - 1];
		if (currentRef) {
			currentRef.scrollIntoView({ behavior: "smooth" });
		}
	}, [activeTasks]);

	useEffect(() => {
		isEdit && getOrderInfo(id);
	}, [id, isEdit]);

	useEffect(() => {
		if (currentOrder && isEdit) {
			const currentOrderData = {
				completionTime:
					convertISO8601Duration({
						isoString: currentOrder.completion_time,
						includeSuffixes: false,
					}) || "",
				customer: currentOrder.customer || "",
				url: currentOrder.url || "",
				comment: currentOrder.comment || "",
				isDifferedOrder: currentOrder.isDifferedOrder || !!currentOrder.started_on || false,
				started_on: currentOrder.started_on || false,
				activityComments: getActivityComments()?.join("\n") || "",
			};
			if (currentOrder.started_on) {
				const formattedDateTime = formatDate({
					dateString: currentOrder.started_on,
					withTime: true,
					year: true,
				});

				const [datePart, timePart] = formattedDateTime.split(" ");

				const date = datePart.split(".").reverse().join("-");
				currentOrderData.started_on_date = date;
				currentOrderData.started_on_time = timePart;
			}

			Object.entries(currentOrderData).forEach(([key, value]) => {
				updateOrderData(key, value);
			});

			setActiveTasks(currentOrder.order_activities || []);
		} else {
			resetOrderForm();
		}
	}, [currentOrder, isEdit]);

	useEffect(() => {
		if (!moduleList?.length) return;
		if (!socialNetworkList?.length) {
			let socialNetworkModule = getSocialNetworkModule();
			if (!socialNetworkModule) return;

			let activeSocialNetworksId = getActiveStateForModule(socialNetworkModule);

			getSocialNetworkList({
				page: 1,
				limit: 100,
				activeSocialNetworksId,
				filters: { state_id: [activeSocialNetworksId] },
			});
		}
	}, [moduleList]);

	useEffect(() => {
		if (!socialNetworkList?.length) return;
		let instagram = getInstagramInfo();
		setSelectedSocial(instagram);
	}, [socialNetworkList]);

	

	useEffect(() => {
		return () => {
			hideModal();
		};
	}, []);

	const handleFetchCustomerList = (searchTerm) => {
		getCustomerList({
			page: 1,
			limit: 100,
			filters: { name: searchTerm },
		});
	};

	const handleSelectCustomer = (customer) => {
		orderData.customer = customer;
	};

	const onTaskCountChange = (count, task) => {
		setActiveTasks(
			activeTasks.map((currentTask) =>
				currentTask.id === task.id ? { ...currentTask, quantity: count } : currentTask
			)
		);
	};

	const createNewCustomer = () => {
		showModal(<CreateCustomer handleSelectCustomer={handleSelectCustomer} />);
	};

	return (
		<form className={classes.form}>
			<OrderSum activeTasks={activeTasks} />

			<div className="row">
				<div className="col-md-6 col-12 mb-3">
					<BrandSearchInput
						fetchData={handleFetchCustomerList}
						clearData={clearCustomerList}
						options={customerList}
						onSelect={handleSelectCustomer}
						placeholder="Не указан"
						field="name"
						label="Заказчик"
						className="position-relative"
						name="name"
						autoComplete="off"
						defaultLiText="+ Добавить нового"
						valid={isFieldValid.customer}
						defaultLiOnClick={createNewCustomer}
						value={orderData.customer}
					/>
				</div>
				<div className="col-md-6 col-12 mb-3">
					<BrandInput
						type="number"
						name="order_time"
						label="Срок выполнения заказа"
						placeholder="0 ч"
						className=""
						unit="ч"
						value={orderData.completionTime}
						onChange={(v) => updateOrderData("completionTime", v)}
						max={1000}
						min={0}
						autoComplete="off"
						valid={isFieldValid.completionTime}
					/>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-6 col-12 mb-3">
					<BrandTextArea
						label="Ссылка"
						placeholder="Ссылка на задание должна быть рабочая"
						value={orderData.url}
						onChange={(v) => updateOrderData("url", v)}
						valid={isFieldValid.url}
						name="url"
					/>
				</div>
				<div className="col-md-6 col-12 mb-3">
					<BrandTextArea
						label="Описание"
						placeholder="Что нужно учесть"
						className=""
						value={orderData.comment}
						onChange={(v) => updateOrderData("comment", v)}
						valid={isFieldValid.comment}
					/>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-12">
					<BrandSwitch
						checked={orderData.isDifferedOrder}
						onChange={(v) => updateOrderData("isDifferedOrder", v)}
						label="Отложенный заказ"
						name="defferedOrder"
						className="my-4"
					/>
				</div>
			</div>

			{orderData.isDifferedOrder && (
				<div className="row mt-3">
					<div className="col-md-6 col-12 mb-3">
						<BrandInput
							label="Дата старта"
							placeholder="дд.мм.гг"
							value={orderData.started_on_date}
							type="date"
							onChange={(v) => updateOrderData("started_on_date", v)}
							valid={isFieldValid.started_on_date}
						/>
					</div>
					<div className="col-md-6 col-12 mb-3">
						<BrandInput
							label="Время старта"
							placeholder="00:00"
							value={orderData.started_on_time}
							onChange={(v) => updateOrderData("started_on_time", v)}
							type="time"
							valid={isFieldValid.started_on_time}
						/>
					</div>
				</div>
			)}

			<div className="row mt-3">
				<div className="col-12">
					<TaskList
						valid={isFieldValid.activeTasks}
						social_network_id={selectedSocial?.id}
						isEdit={isEdit}
					/>
				</div>
			</div>

			{activeTasks?.length > 0 &&
				activeTasks.map((task, index) => {
					return (
						<TaskBlock
							key={task.id}
							title={getTaskTitle(task)}
							onValueChange={(count) => onTaskCountChange(count, task)}
							quantity={task.quantity}
							ref={(el) => (taskRefs.current[index] = el)}
						/>
					);
				})}
		</form>
	);
};
