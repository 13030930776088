import React from "react";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import useAuthStore from "@store/AuthStore";
import { USER_ROLE } from "@const/roles";
import { HEADER_MENU } from "@const/headerConfig";
import classes from "./Sidebar.module.css";

const Sidebar = () => {
	const { userRole } = useAuthStore();

	const MENU = {
		[USER_ROLE.MANAGER]: HEADER_MENU.MANAGER_HEADER_LINKS,
		[USER_ROLE.CONTRACTOR]: HEADER_MENU.CONTRACTOR_HEADER_LINKS,
		[USER_ROLE.ADMINISTRATOR]: HEADER_MENU.ADMINISTRATOR_HEADER_LINKS,
	};

	return (
		<div className={classes.sidebar}>
			{userRole && <SidebarMenu menu={MENU[userRole] || MENU[USER_ROLE.MANAGER]} />}
		</div>
	);
};

export default Sidebar;
