import useModalStore from "@store/ModalStore";
import useRequestStore from "@store/RequestStore";
import useTaskStore from "@store/TaskStore";
import { useEffect } from "react";

const OrderActivityListener = ({ loadTasks, clearTasks }) => {
	const { subscribeToEvent, unsubscribeFromEvent } = useRequestStore();
	const { getMyTasks } = useTaskStore();
	const { hideModal, getModalState, getModalContent } = useModalStore();

	const handleIncomingData = (data) => {
		const isShowModal = getModalState();
		const modalContent = getModalContent();
		const currentTaskInModal = modalContent?.props?.task;

		if (isShowModal && currentTaskInModal.order_activity_id === data?.item?.order_activity_id) {
			hideModal();
		}
		clearTasks();
		loadTasks({ page: 1 });
		getMyTasks({ page: 1, limit: 100 });
	};

	useEffect(() => {
		subscribeToEvent("order_user_activities:expired", handleIncomingData);

		return () => {
			unsubscribeFromEvent("order_user_activities:expired");
		};
	}, []);

	return null;
};

export default OrderActivityListener;
